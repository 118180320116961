<template>
  <div class="forgotpasswordreset">
    <br>
    <h1>{{ $t('account.forgotpasswordreset.title') }}</h1>
    <div>
      <b-row>
        <b-col cols="3"></b-col>
        <b-col cols="6">
          <p class="text-center">{{ $t('account.forgotpasswordreset.text') }}</p>
          <b-form @submit="onSubmit">
            <label for="text-password">{{ $t('account.forgotpasswordreset.password') }}</label>
            <b-form-input type="password" id="text-password" v-model="form.password"></b-form-input>
            <br>
            <b-button type="submit" variant="primary"><font-awesome-icon icon="key" class="fa-lg" /></b-button>
          </b-form>
        </b-col>
        <b-col cols="3"></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { postData } from '@/api'

  export default {
    name: 'Forgotpasswordreset',
    data() {
      return {
        form: {
          resetkey: null,
          password: ''
        }
      }
    },
    created () {
      this.checkroutedata()
    },
    methods: {
      checkroutedata() {
        if (!(Object.keys(this.$route.params).length === 0)) {
          this.form.resetkey = this.$route.params.resetkey
        }
      },
      onSubmit(event) {
        event.preventDefault()
        postData('account/forgotpaswordreset', this.form, (err) => {
          if (err) {
            this.$root.$bvToast.toast(this.$t('account.forgotpasswordreset.error'), {
              title: this.$t('account.forgotpasswordreset.errortitle'),
              variant: "danger",
              autoHideDelay: 2500,
            })
          } else {
            // show success toast
            this.$root.$bvToast.toast(this.$t('account.forgotpasswordreset.success'), {
              title: this.$t('account.forgotpasswordreset.successtitle'),
              autoHideDelay: 2500,
            })
            this.$router.push({ name: 'Login' })
          }
        })
      }
    }
  }
</script>
