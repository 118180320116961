<template>
  <div class="forgotpassword">
    <br>
    <h1>{{ $t('account.forgotpassword.title') }}</h1>
    <div>
      <b-row>
        <b-col cols="3"></b-col>
        <b-col cols="6">
          <p class="text-center">{{ $t('account.forgotpassword.text') }}</p>
          <b-form @submit="onSubmit">
            <label for="text-email">{{ $t('account.forgotpassword.email') }}</label>
            <b-form-input type="email" id="text-email" v-model="form.email"></b-form-input>
            <br>
            <b-button type="submit" variant="primary" v-b-tooltip.hover :title="$t('account.forgotpassword.button')" id="user-forgot-submit"><font-awesome-icon icon="paper-plane-top" class="fa-lg" /></b-button>
          </b-form>
        </b-col>
        <b-col cols="3"></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { postData } from '@/api'

  export default {
    name: 'Forgotpassword',
    data() {
      return {
        form: {
          email: ''
        }
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        postData('account/forgotpaswordstart', this.form, (err) => {
          if (err) {
            this.$root.$bvToast.toast(this.$t('account.forgotpassword.error'), {
              title: this.$t('account.forgotpassword.errortitle'),
              variant: "danger",
              autoHideDelay: 2500,
            })
          } else {
            // show success toast
            this.$root.$bvToast.toast(this.$t('account.forgotpassword.success'), {
              title: this.$t('account.forgotpassword.successtitle'),
              autoHideDelay: 2500,
            })
            this.$router.push({ name: 'Login' })
          }
        })
      }
    }
  }
</script>
