import { createRouter, createWebHistory } from 'vue-router'

// always loaded items here

import Home from '../views/Home.vue'
import UserLogin from '../views/user/Login.vue'
import Loginsmartschool from '../views/Loginsmartschool.vue'
import UserLogout from '../views/user/Logout.vue'
import UserForgotpassword from '../views/user/Forgotpassword.vue'
import UserForgotpasswordreset from '../views/user/Forgotpasswordreset.vue'
import NotFound from '../views/NotFound.vue'

// lazy load all below here here

// user

const UserChangepassword = () =>  import(/* webpackChunkName: "user" */ '../views/user/Changepassword.vue')
const UserCalendarSettings = () =>  import(/* webpackChunkName: "user" */ '../views/user/Calendarsettings.vue')
const UserSwitch = () =>  import(/* webpackChunkName: "user" */ '../views/user/Switch.vue')

// matrix

const MatrixEntry = () =>  import(/* webpackChunkName: "matrix" */ '../views/matrix/Entry.vue')
const MatrixReport = () =>  import(/* webpackChunkName: "matrix" */ '../views/matrix/Report.vue')

// ihp

const IhpEntry = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Entry.vue')
const IhpEntryMeasures = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Entrymeasures.vue')
const IhpEntryContent = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Entrycontent.vue')
const IhpReport = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Report.vue')
const IhpNewReport = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Newreport.vue')
const IhpOverview = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Overview.vue')
const IhpOverviewOv3 = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Overviewov3.vue')
const IhpReportOv3 = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Reportov3.vue')
const IhpCheckentry = () =>  import(/* webpackChunkName: "ihp" */ '../views/ihp/Checkentry.vue')
const Gwp = () =>  import(/* webpackChunkName: "ihp" */ '../views/Gwp.vue')

// competence

const CompetenceEntry = () =>  import(/* webpackChunkName: "competence" */ '../views/competence/Entry.vue')
const CompetenceReport = () =>  import(/* webpackChunkName: "competence" */ '../views/competence/Report.vue')
const CompetenceDiscussion = () =>  import(/* webpackChunkName: "competence" */ '../views/competence/Discussion.vue')

// admin

const AdminLog = () =>  import(/* webpackChunkName: "admin" */ '../views/admin/Log.vue')
const AdminSpread = () =>  import(/* webpackChunkName: "admin" */ '../views/admin/Spread.vue')
const AdminMatrixGraphs = () =>  import(/* webpackChunkName: "admin" */ '../views/admin/Matrixgraphs.vue')
const AdminCheckEntry = () =>  import(/* webpackChunkName: "admin" */ '../views/admin/Checkentry.vue')
const SettingsCourses = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Courses.vue')
const SettingsMessages = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Messages.vue')
const SettingsClasses = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Classes.vue')
const SettingsTeachers = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Teachers.vue')
const SettingsClasstimes = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Classtimes.vue')
const SettingsClassschedules = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Classschedules.vue')
const SettingsCompetences = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Competences.vue')
const SettingsCompetenceAnchors = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/CompetenceAnchors.vue')
const SettingsGoals = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Goals.vue')
const SettingsSuboals = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Subgoals.vue')
const SettingsStudents = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Students.vue')
const SettingsParameters = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Parameters.vue')
const SettingsParametersSuggestions = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Parameterssuggestions.vue')
const SettingsSubgoalsSuggestions = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Subgoalssuggestions.vue')
const SettingsPerspectives = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Perspectives.vue')
const SettingsCategories = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Categories.vue')
const SettingsGeneral = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/General.vue')
const SettingsCalendars = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Calendars.vue')
const SettingsRooms = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Rooms.vue')
const SettingsTeachergroups = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Teachergroups.vue')
const SettingsEvaluationmoments = () =>  import(/* webpackChunkName: "admin" */ '../views/settings/Evaluationmoments.vue')
const AdminArchive = () =>  import(/* webpackChunkName: "admin" */ '../views/admin/Archive.vue')

// pos

const PosOrders = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Orders.vue')
const PosOrdersRange = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Ordersrange.vue')
const PosTopups = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Topups.vue')
const PosTopupsRange = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Topupsrange.vue')
const PosProducts = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Products.vue')
const PosStudentOverview = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Studentoverview.vue')
const PosTeacherOverview = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Teacheroverview.vue')
const PosStudents = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Students.vue')
const PosTeachers = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Teachers.vue')
const PosWebpos = () =>  import(/* webpackChunkName: "pos" */ '../views/pos/Webpos.vue')

// attendance

const Attendance = () =>  import(/* webpackChunkName: "attendance" */ '../views/Attendance.vue')

// calendar

const Calendar = () =>  import(/* webpackChunkName: "calendar" */ '../views/Calendar.vue')

// pw reset

const Pwreset = () =>  import(/* webpackChunkName: "pwreset" */ '../views/Pwreset.vue')

// messages

const Messages = () =>  import(/* webpackChunkName: "messages" */ '../views/Messages.vue')

// file

const File = () =>  import(/* webpackChunkName: "file" */ '../views/File.vue')

// agenda

const AgendaEntry = () =>  import(/* webpackChunkName: "agenda" */ '../views/agenda/Entry.vue')
const AgendaEntryov3 = () =>  import(/* webpackChunkName: "agenda" */ '../views/agenda/Entryov3.vue')
const AgendaReport = () =>  import(/* webpackChunkName: "agenda" */ '../views/agenda/Report.vue')
const AgendaReportClass = () =>  import(/* webpackChunkName: "agenda" */ '../views/agenda/Reportclass.vue')
const AgendaReportTeacher = () =>  import(/* webpackChunkName: "agenda" */ '../views/agenda/Reportteacher.vue')

// reports

const ReportsGenerate = () =>  import(/* webpackChunkName: "reports" */ '../views/reports/Generatereports.vue')
const ReportsCourses = () =>  import(/* webpackChunkName: "reports" */ '../views/reports/Courses.vue')
const ReportsSubgoals = () =>  import(/* webpackChunkName: "reports" */ '../views/reports/Subgoals.vue')
const ReportsGoals = () =>  import(/* webpackChunkName: "reports" */ '../views/reports/Goals.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/loginsmartschool',
    name: 'Loginsmartschool',
    component: Loginsmartschool
  },
  {
    path: '/user/login',
    name: 'Login',
    component: UserLogin,
  },
  {
    path: '/user/changepassword',
    name: 'ChangePassword',
    component: UserChangepassword,
  },
  {
    path: '/user/calendarsettings',
    name: 'UserCalendarSettings',
    component: UserCalendarSettings,
  },
  {
    path: '/user/switch',
    name: 'UserSwitch',
    component: UserSwitch,
  },
  {
    path: '/user/logout',
    name: 'Logout',
    component: UserLogout,
  },
  {
    path: '/user/forgotpassword',
    name: 'UserForgotpassword',
    component: UserForgotpassword
  },
  {
    path: '/user/forgotpassword/:resetkey',
    name: 'UserForgotpasswordreset',
    component: UserForgotpasswordreset,
    props: true
  },
  {
    path: '/matrix/entry/:classid',
    name: 'Matrix',
    component: MatrixEntry,
    props: true
  },
  {
    path: '/matrix/report/:classid',
    name: 'MatrixReport',
    component: MatrixReport,
    props: true
  },
  {
    path: '/ihp/entry/:classid/:student/:period/:course/:goal/:domain',
    name: 'IhpEntry',
    component: IhpEntry,
    props: true
  },
  {
    path: '/ihp/entrymeasures/:classid/:student',
    name: 'IhpEntryMeasures',
    component: IhpEntryMeasures,
    props: true
  },
  {
    path: '/ihp/entrycontent/:classid/:period/:student/:course/:goal/:entrykind',
    name: 'IhpEntryContent',
    component: IhpEntryContent,
    props: true
  },
  {
    path: '/ihp/report/:classid/:student/:period',
    name: 'IhpReport',
    component: IhpReport,
    props: true
  },
  {
    path: '/ihp/newreport/:classid/:student/:period',
    name: 'IhpNewReport',
    component: IhpNewReport,
    props: true
  },
  {
    path: '/ihp/overview/:classid/:student/:domain',
    name: 'IhpOverview',
    component: IhpOverview,
    props: true
  },
  {
    path: '/ihp/overviewov3/:classid/:period/:course',
    name: 'IhpOverviewOv3',
    component: IhpOverviewOv3,
    props: true
  },
  {
    path: '/ihp/reportov3/:classid/:period/:student',
    name: 'IhpReportOv3',
    component: IhpReportOv3,
    props: true
  },
  {
    path: '/ihp/checkentry',
    name: 'IhpCheckentry',
    component: IhpCheckentry
  },
  {
    path: '/gwp/:classid/:course/:period/:teacher',
    name: 'Gwp',
    component: Gwp,
    props: true,
  },
  {
    path: '/competence/entry/:classid/:student/:period/:course',
    name: 'CompetenceEntry',
    component: CompetenceEntry,
    props: true
  },
  {
    path: '/competence/report/:classid/:student',
    name: 'CompetenceReport',
    component: CompetenceReport,
    props: true
  },
  {
    path: '/competence/discussion/:classid/:student/:period',
    name: 'CompetenceDiscussion',
    component: CompetenceDiscussion,
    props: true
  },
  {
    path: '/agenda/entry/:classid/:period/:course/:date',
    name: 'AgendaEntry',
    component: AgendaEntry,
    props: true
  },
  {
    path: '/agenda/entryov3/:classid/:date',
    name: 'AgendaEntryov3',
    component: AgendaEntryov3,
    props: true
  },
  {
    path: '/agenda/report/:classid/:course',
    name: 'AgendaReport',
    component: AgendaReport,
    props: true
  },
  {
    path: '/agenda/reportclass/:classid',
    name: 'AgendaReportClass',
    component: AgendaReportClass,
    props: true
  },
  {
    path: '/agenda/reportteacher/:teacher',
    name: 'AgendaReportTeacher',
    component: AgendaReportTeacher,
    props: true
  },
  {
    path: '/file/:classid/:student',
    name: 'File',
    component: File,
    props: true
  },
  {
    path: '/attendance/:date',
    name: 'Attendance',
    component: Attendance,
    props: true
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    props: true
  },
  {
    path: '/pos/orders',
    name: 'PosOrders',
    component: PosOrders
  },
  {
    path: '/pos/ordersrange',
    name: 'PosOrdersRange',
    component: PosOrdersRange
  },
  {
    path: '/pos/topups',
    name: 'PosTopups',
    component: PosTopups
  },
  {
    path: '/pos/topupsrange',
    name: 'PosTopupsRange',
    component: PosTopupsRange
  },
  {
    path: '/pos/products',
    name: 'PosProducts',
    component: PosProducts
  },
  {
    path: '/pos/studentoverview',
    name: 'PosStudentOverview',
    component: PosStudentOverview
  },
  {
    path: '/pos/teacheroverview',
    name: 'PosTeacherOverview',
    component: PosTeacherOverview
  },
  {
    path: '/pos/students',
    name: 'PosStudents',
    component: PosStudents
  },
  {
    path: '/pos/teachers',
    name: 'PosTeachers',
    component: PosTeachers
  },
  {
    path: '/pos/webpos',
    name: 'PosWebpos',
    component: PosWebpos
  },
  {
    path: '/settings/courses',
    name: 'SettingsCourses',
    component: SettingsCourses
  },
  {
    path: '/settings/messages',
    name: 'SettingsMessages',
    component: SettingsMessages
  },
  {
    path: '/settings/classes',
    name: 'SettingsClasses',
    component: SettingsClasses
  },
  {
    path: '/settings/teachers',
    name: 'SettingsTeachers',
    component: SettingsTeachers
  },
  {
    path: '/settings/classtimes',
    name: 'SettingsClasstimes',
    component: SettingsClasstimes
  },
  {
    path: '/settings/classschedules',
    name: 'SettingsClassschedules',
    component: SettingsClassschedules
  },
  {
    path: '/settings/competences',
    name: 'SettingsCompetences',
    component: SettingsCompetences
  },
  {
    path: '/settings/competenceanchors',
    name: 'SettingsCompetenceAnchors',
    component: SettingsCompetenceAnchors
  },
  {
    path: '/settings/goals',
    name: 'SettingsGoals',
    component: SettingsGoals
  },
  {
    path: '/settings/subgoals',
    name: 'SettingsSubgoals',
    component: SettingsSuboals
  },
  {
    path: '/settings/students',
    name: 'SettingsStudents',
    component: SettingsStudents
  },
  {
    path: '/settings/parameters',
    name: 'SettingsParameters',
    component: SettingsParameters
  },
  {
    path: '/settings/parameterssuggestions',
    name: 'SettingsParametersSuggestions',
    component: SettingsParametersSuggestions
  },
  {
    path: '/settings/subgoalssuggestions',
    name: 'SettingsSubgoalsSuggestions',
    component: SettingsSubgoalsSuggestions
  },
  {
    path: '/settings/general',
    name: 'SettingsGeneral',
    component: SettingsGeneral
  },
  {
    path: '/settings/perspectives',
    name: 'SettingsPerspectives',
    component: SettingsPerspectives
  },
  {
    path: '/settings/categories',
    name: 'SettingsCategories',
    component: SettingsCategories
  },
  {
    path: '/settings/calendars',
    name: 'SettingsCalendars',
    component: SettingsCalendars
  },
  {
    path: '/settings/rooms',
    name: 'SettingsRooms',
    component: SettingsRooms
  },
  {
    path: '/settings/teachergroups',
    name: 'SettingsTeachergroups',
    component: SettingsTeachergroups
  },
  {
    path: '/settings/evaluationmoments',
    name: 'SettingsEvaluationmoments',
    component: SettingsEvaluationmoments
  },
  {
    path: '/admin/archive/:student/',
    name: 'AdminArchive',
    component: AdminArchive,
    props: true
  },
  {
    path: '/reports/courses',
    name: 'ReportsCourses',
    component: ReportsCourses
  },
  {
    path: '/admin/log',
    name: 'AdminLog',
    component: AdminLog
  },
  {
    path: '/reports/generatereports',
    name: 'ReportsGenerate',
    component: ReportsGenerate
  },
  {
    path: '/admin/spread',
    name: 'AdminSpread',
    component: AdminSpread
  },
  {
    path: '/admin/matrixgraphs',
    name: 'AdminMatrixGraphs',
    component: AdminMatrixGraphs
  },
  {
    path: '/admin/checkentry',
    name: 'AdminCheckEntry',
    component: AdminCheckEntry
  },
  {
    path: '/reports/subgoals',
    name: 'ReportsSubgoals',
    component: ReportsSubgoals
  },
  {
    path: '/reports/goals',
    name: 'ReportsGoals',
    component: ReportsGoals
  },
  {
    path: '/pwreset',
    name: 'Pwreset',
    component: Pwreset
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
