import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { BootstrapVue } from 'bootstrap-vue'
import $ from "jquery";
import { Integrations } from "@sentry/tracing";
import Verte from 'verte';
import 'verte/dist/verte.css';
import './app.scss'
import router from './router'
import i18n from './i18n'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faHome, faQuestionCircle, faUsersClass, faUsers, faUser, faFolders, faCalendarCheck, faUserCheck, faUserTag, faAward, faShoppingCart, faFileChartPie, faCogs, faUsersCrown, faUnlock, faSignIn, faSpinner, faPrint, faEdit, faCompress, faInfo, faExpandArrows, faSave, faTrash, faCopy, faBusinessTime, faFileExport, faPlusSquare, faPlus, faTimes, faCheck, faKey, faCabinetFiling, faMinusCircle, faEye, faEyeSlash, faUpload, faChevronRight, faTasks, faSync, faChevronSquareRight, faChevronSquareLeft, faTools, faPen, faFileArrowDown, faCartPlus, faPaperPlaneTop, faCircle, faUserPlus, faFilePlusMinus, faChevronLeft, faBallotCheck, faSort, faArrowUpZA, faSquare, faCalendarUsers, faCalendarWeek, faCalendarDays, faBuilding, faBell, faMessages, faMessage, faMessageCheck, faFlag, faTriangle, faBold, faUnderline, faItalic, faArrowLeft, faArrowRight, faList, faListOl, faStrikethrough, faQuotes, faImage, faListCheck, faLeft, faRight, faBroomWide, faTable } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.use(BootstrapVue)
Vue.use(Verte, {
  recentColors: JSON.parse(localStorage.getItem('colors')),
  onRecentColorsChange (colors) {
    localStorage.setItem('colors', JSON.stringify(colors));
  }
});

library.add(faUserSecret, faHome, faQuestionCircle, faUsersClass, faUsers, faUser, faFolders, faCalendarCheck, faUserCheck, faUserTag, faAward, faShoppingCart, faFileChartPie, faCogs, faUsersCrown, faUnlock, faSignIn, faSpinner, faPrint, faEdit, faCompress, faInfo, faExpandArrows, faSave, faTrash, faCopy, faBusinessTime, faFileExport, faPlusSquare, faPlus, faTimes, faCheck, faKey, faCabinetFiling, faMinusCircle, faEye, faEyeSlash, faUpload, faChevronRight, faTasks, faSync, faChevronSquareRight, faChevronSquareLeft, faTools, faPen, faFileArrowDown, faCartPlus, faPaperPlaneTop, faCircle, faUserPlus, faFilePlusMinus, faChevronLeft, faBallotCheck, faSort, faArrowUpZA, faSquare, faCalendarUsers, faCalendarWeek, faCalendarDays, faBuilding, faBell, faMessages, faMessage, faMessageCheck, faFlag, faTriangle, faBold, faUnderline, faItalic, faArrowLeft, faArrowRight, faList, faListOl, faStrikethrough, faQuotes, faImage, faListCheck, faLeft, faRight, faBroomWide, faTable )

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
//Vue.config.performance = true

export const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://900705bd5c674bf5aff27fed057b146a@o1113613.ingest.sentry.io/6144286",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [/^((\*|[\w\d]+(-[\w\d]+)*)\.)*(toekomstperspectief.be)/],
    }),
  ],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
  release: "toekomstperspectief-webapp-frontend@" + process.env.VUE_APP_VERSION,
});

app.use(store)
app.use(i18n)
app.use(router)
app.mount("#app");

// load data from api's into store

$.ajax({
  url: '/api/v3/user.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      // set userid in sentry

      Sentry.setUser({ id: response.data.user.id + '@' + response.data.baseurl });

      store.commit('setbaseurl', response.data.baseurl)
      store.commit('setuserdata', response.data.user)

    } else if ("error" in response) {

      if (response.error == 'notloggedin') {

        // check if on login page or on Passwordresetpage. If not redirect to login.

        if (router.currentRoute.name != 'Login' && router.currentRoute.name != 'UserForgotpasswordreset') {

          router.push({ name: 'Login' })

        }

        store.commit('setuserloggedout')

      }

    } else {

      //cb(new Error('Comms error.'))

    }

  },
  error: function() {
    //cb(new Error('Comms error.'))
  }

});

$.ajax({

  url: '/api/v3/systemsettings.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setsettings', response.data.settings)

    }

  }

});

$.ajax({

  url: '/api/v3/generalinfo.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setgeneralinfo', response.data.generalinfo)

    }

  }

});

$.ajax({

  url: '/api/v3/messages.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setmessages', response.data.messages)

    }

  }

});

$.ajax({

  url: '/api/v3/teachers.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setteachers', response.data.teachers)

    }

  }

});

$.ajax({

  url: '/api/v3/classes.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setclasses', response.data.classes)

    }

  }

});

$.ajax({

  url: '/api/v3/students.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setstudents', response.data.students)

    }

  }

});

$.ajax({

  url: '/api/v3/hppe.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('sethppe', response.data.hppe)

    }

  }

});

$.ajax({

  url: '/api/v3/levels.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setlevels', response.data.levels)

    }

  }

});

$.ajax({

  url: '/api/v3/matrixparameters.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setmatrixparameters', response.data.matrixparameters)

    }

  }

});

$.ajax({

  url: '/api/v3/evaluationmoments.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setevaluationmoments', response.data.evaluationmoments)

    }

  }

});

$.ajax({

  url: '/api/v3/evaluationmomentscompetence.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setevaluationmomentscompetence', response.data.evaluationmomentscompetence)

    }

  }

});

$.ajax({

  url: '/api/v3/perspectives.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setperspectives', response.data.perspectives)

    }

  }

});

$.ajax({

  url: '/api/v3/competenceparameters.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setcompetenceparameters', response.data.competenceparameters)

    }

  }

});

$.ajax({

  url: '/api/v3/coursegroups.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setcoursegroups', response.data.coursegroups)

    }

  }

});

$.ajax({

  url: '/api/v3/goalcategories.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setgoalcategories', response.data.goalcategories)

    }

  }

});

$.ajax({

  url: '/api/v3/educationforms.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('seteducationforms', response.data.educationforms)

    }

  }

});

$.ajax({

  url: '/api/v3/anchors.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setanchors', response.data.anchors)

    }

  }

});

$.ajax({

  url: '/api/v3/parameters.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setparameters', response.data.parameters)

    }

  }

});

$.ajax({

  url: '/api/v3/goals.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setgoals', response.data.goals)

    }

  }

});

$.ajax({

  url: '/api/v3/courses.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setcourses', response.data.courses)

    }

  }

});

$.ajax({

  url: '/api/v3/products.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setproducts', response.data.products)

    }

  }

});

$.ajax({

  url: '/api/v3/classtimes.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setclasstimes', response.data.classtimes)

    }

  }

});

$.ajax({

  url: '/api/v3/subgoals.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setsubgoals', response.data.subgoals)

    }

  }

});

$.ajax({

  url: '/api/v3/rooms.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setrooms', response.data.rooms)

    }

  }

});

$.ajax({

  url: '/api/v3/teachergroups.php',
  type: 'get',
  dataType: 'json',
  success: function(response){

    if (response.success == true) {

      store.commit('setteachergroups', response.data.teachergroups)

    }

  }

});

function poll() {
  setTimeout(function() {
    $.ajax({
      url: '/api/v3/heartbeat.php',
      type: 'get',
      dataType: 'json',
      success: function(response){
        if (response.success == true) {
          poll();
        } else if ("error" in response) {

          if (response.error == 'notloggedin') {

            if (router.currentRoute.name != 'Login' && router.currentRoute.name != 'UserForgotpasswordreset') {

              router.push({ name: 'Login' })

            }

          }

        }
      }
    });
  }, 120000);
}

poll();

// reload page if chunk fails to load.

router.onError(error => {
  console.log(error.message)
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})