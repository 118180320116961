<template>
  <div>
    <div v-if="$store.state.user.id">
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand :to="{ name: 'Home' }"><img src="/assets/images/logo.png" alt="logo" width="100" height="33"></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'Home' }" :class="{active: routeName==='Home'}" v-b-tooltip.hover :title="$t('navbar.home')"><font-awesome-icon icon="home" class="fa-lg" /></b-nav-item>
            <div v-if="!$store.state.settings.moduleMATRIXdisabled && ( $store.state.user.ov1 == true || $store.state.user.ov3 == true )">
              <b-nav-item :to="{ name: 'Matrix', params: { classid: 0 } }" :class="{active: routeName==='Matrix'}" v-b-tooltip.hover :title="$t('navbar.matrix')"><font-awesome-icon icon="users-class" class="fa-lg" /></b-nav-item>
            </div>
            <div v-if="!$store.state.settings.moduleIHPdisabled && ( $store.state.user.ov1 == true || $store.state.user.ov3 == true )">
              <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.ihp.title')" :class="{active: routeName.startsWith('Ihp')}">
                <template #button-content><font-awesome-icon icon="user-tag" class="fa-lg" /></template>
                <span v-if="$store.state.user.ov1 == true">
                  <b-dropdown-item :to="{ name: 'IhpEntry', params: { classid: 0, student: 0, period: 0, course: 0, goal: 0, domain: 0 } }">{{ $t('navbar.ihp.entry') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'IhpReport', params: { classid: 0, student: 0, period: 0 } }">{{ $t('navbar.ihp.report') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'IhpOverview', params: { classid: 0, student: 0, domain: 0 } }">{{ $t('navbar.ihp.overview') }}</b-dropdown-item>
                </span>
                <span v-if="$store.state.baseurl == '10.250.5.200:8080/' || $store.state.baseurl == 'staging.toekomstperspectief.be/' || $store.state.baseurl == 'demo.toekomstperspectief.be/' || $store.state.baseurl == 'dezeeparel.toekomstperspectief.be/'">
                  <b-dropdown-item :to="{ name: 'IhpNewReport', params: { classid: 0, student: 0, period: 0 } }">{{ $t('navbar.ihp.newreport') }}</b-dropdown-item>
                </span>
                <span v-if="$store.state.user.ov3 == true">
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :to="{ name: 'IhpEntryMeasures', params: { classid: 0, student: 0 } }">{{ $t('navbar.ihp.entrymeasures') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'IhpEntryContent', params: { classid: 0, student: 0, period: 0, course: 0, goal: 0, entrykind: 0 } }">{{ $t('navbar.ihp.entrycontent') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'IhpOverviewOv3', params: { classid: 0, period: 0, course: 0 } }">{{ $t('navbar.ihp.overviewov3') }}</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'IhpReportOv3', params: { classid: 0, period: 0, student: 0 } }">{{ $t('navbar.ihp.reportov3') }}</b-dropdown-item>
                </span>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'IhpCheckentry' }">{{ $t('navbar.ihp.checkentry') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <div v-if="!$store.state.settings.moduleIHPdisabled && $store.state.user.ov1 == true">
              <b-nav-item :to="{ name: 'Gwp', params: { classid: 0, teacher: 0, period: 0, course: 0 } }" :class="{active: routeName==='Gwp'}" v-b-tooltip.hover :title="$t('navbar.gwp')"><font-awesome-icon icon="users" class="fa-lg" /></b-nav-item>
            </div>
            <div v-if="!$store.state.settings.moduleCOMPETENTIEdisabled">
              <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.competence.title')" :class="{active: routeName.startsWith('Competence')}">
                <template #button-content><font-awesome-icon icon="award" class="fa-lg" /></template>
                <b-dropdown-item :to="{ name: 'CompetenceEntry', params: { classid: 0, student: 0, period: 0, course: 0 } }">{{ $t('navbar.competence.entry') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'CompetenceDiscussion', params: { classid: 0, student: 0, period: 0 } }">{{ $t('navbar.competence.discussion') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'CompetenceReport', params: { classid: 0, student: 0 } }">{{ $t('navbar.competence.report') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <b-nav-item :to="{ name: 'File', params: { classid: 0, student: 0 } }" :class="{active: routeName==='File'}" v-b-tooltip.hover :title="$t('navbar.file')"><font-awesome-icon icon="folders" class="fa-lg" /></b-nav-item>
            <div v-if="$store.state.user.ov3 == true && $store.state.user.ov1 == false">
              <b-nav-item :to="{ name: 'AgendaEntryov3', params: { classid: 0, date: 0 } }" :class="{active: routeName.startsWith('Agenda')}" v-b-tooltip.hover :title="$t('navbar.agenda.title')"><font-awesome-icon icon="calendar-check" class="fa-lg" /></b-nav-item>
            </div>
            <div v-else>
              <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.agenda.title')" :class="{active: routeName.startsWith('Agenda')}">
                <template #button-content><font-awesome-icon icon="calendar-check" class="fa-lg" /></template>
                <b-dropdown-item :to="{ name: 'AgendaEntry', params: { classid: 0, date: 0, period: 0, course: 0 } }" v-if="$store.state.user.ov1 == true">{{ $t('navbar.agenda.entry') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AgendaEntryov3', params: { classid: 0, date: 0 } }" v-if="$store.state.user.ov3 == true">{{ $t('navbar.agenda.entryov3') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AgendaReport', params: { classid: 0, course: 0 } }" v-if="$store.state.user.ov1 == true">{{ $t('navbar.agenda.report') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AgendaReportClass', params: { classid: 0 } }" v-if="$store.state.user.ov1 == true">{{ $t('navbar.agenda.reportclass') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AgendaReportTeacher', params: { teacher: 0 } }" v-if="$store.state.user.ov1 == true">{{ $t('navbar.agenda.reportteachers') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <div>
              <b-nav-item :to="{ name: 'Calendar' }" :class="{active: routeName==='Calendar'}" v-b-tooltip.hover :title="$t('navbar.calendar')"><font-awesome-icon icon="calendar-users" class="fa-lg" /></b-nav-item>
            </div>
            <div v-if="$store.state.user.id == 1">
              <b-nav-item :to="{ name: 'Messages' }" :class="{active: routeName==='messages'}" v-b-tooltip.hover :title="$t('navbar.messages')"><font-awesome-icon icon="messages" class="fa-lg" /></b-nav-item>
            </div>
            <div v-if="!$store.state.settings.modulePOSdisabled">
              <b-nav-item :to="{ name: 'Attendance', params: { date: 0 } }" :class="{active: routeName==='Attendance'}" v-b-tooltip.hover :title="$t('navbar.attendance')"><font-awesome-icon icon="user-check" class="fa-lg" /></b-nav-item>
            </div>
            <div v-if="!$store.state.settings.modulePOSdisabled">
              <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.pos.title')" :class="{active: routeName.startsWith('Pos')}">
                <template #button-content><font-awesome-icon icon="shopping-cart" class="fa-lg" /></template>
                <b-dropdown-item :to="{ name: 'PosOrders' }">{{ $t('navbar.pos.orders') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosTopups' }">{{ $t('navbar.pos.topups') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosProducts' }">{{ $t('navbar.pos.products') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosStudentOverview' }">{{ $t('navbar.pos.studentoverview') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosStudents' }">{{ $t('navbar.pos.students') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosTeacherOverview' }">{{ $t('navbar.pos.teacheroverview') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosTeachers' }">{{ $t('navbar.pos.teachers') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosWebpos' }">{{ $t('navbar.pos.webpos') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosOrdersRange' }">{{ $t('navbar.pos.ordersrange') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'PosTopupsRange' }">{{ $t('navbar.pos.topupsrange') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <div v-if="$store.state.user.administrator">
              <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.settings.title')">
                <template #button-content><font-awesome-icon icon="cogs" class="fa-lg" /></template>
                <b-dropdown-item :to="{ name: 'SettingsMessages' }">{{ $t('navbar.settings.messages') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'SettingsCourses' }">{{ $t('navbar.settings.courses') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsClasses' }">{{ $t('navbar.settings.classes') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsTeachers' }">{{ $t('navbar.settings.teachers') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsStudents' }">{{ $t('navbar.settings.students') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsTeachergroups' }">{{ $t('navbar.settings.teachergroups') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsRooms' }">{{ $t('navbar.settings.rooms') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsClasstimes' }">{{ $t('navbar.settings.classtimes') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsClassschedules' }" v-if="$store.state.user.id == 1">{{ $t('navbar.settings.classschedules') }}</b-dropdown-item>                
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'SettingsCompetences' }">{{ $t('navbar.settings.competences') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsCompetenceAnchors' }">{{ $t('navbar.settings.competenceanchors') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'SettingsCalendars' }">{{ $t('navbar.settings.calendars') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'SettingsGoals' }">{{ $t('navbar.settings.goals') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsParameters' }">{{ $t('navbar.settings.parameters') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsSubgoals' }">{{ $t('navbar.settings.subgoals') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsSubgoalsSuggestions' }">{{ $t('navbar.settings.subgoalssuggestions') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsParametersSuggestions' }">{{ $t('navbar.settings.parameterssuggestions') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsPerspectives' }">{{ $t('navbar.settings.perspectives') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsCategories' }">{{ $t('navbar.settings.categories') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'SettingsEvaluationmoments' }">{{ $t('navbar.settings.evaluationmoments') }}</b-dropdown-item>
                <b-dropdown-item v-if="$store.state.user.id == 1" :to="{ name: 'SettingsGeneral' }">{{ $t('navbar.settings.general') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <div v-if="$store.state.user.administrator">
              <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.admin.title')" :class="{active: routeName.startsWith('Admin')}">
                <template #button-content><font-awesome-icon icon="users-crown" class="fa-lg" /></template>
                <b-dropdown-item :to="{ name: 'AdminLog' }">{{ $t('navbar.admin.log') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AdminCheckEntry' }">{{ $t('navbar.admin.checkadmin') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AdminMatrixGraphs' }">{{ $t('navbar.admin.matrixgraphs') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AdminSpread' }">{{ $t('navbar.admin.spread') }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'AdminArchive', params: { student: 0 } }">{{ $t('navbar.admin.archive') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.reports.title')" :class="{active: routeName.startsWith('Reports')}">
              <template #button-content><font-awesome-icon icon="file-chart-pie" class="fa-lg" /></template>
              <b-dropdown-item :to="{ name: 'ReportsGenerate' }">{{ $t('navbar.reports.generate') }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'ReportsCourses' }">{{ $t('navbar.reports.courses') }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'ReportsSubgoals' }">{{ $t('navbar.reports.subgoals') }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'ReportsGoals' }">{{ $t('navbar.reports.goals') }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <div v-if="!$store.state.settings.moduleSMPWRESETdisabled && $store.state.user.smpasswordreset == true">
              <b-nav-item :to="{ name: 'Pwreset' }" :class="{active: routeName==='pwreset'}" v-b-tooltip.hover :title="$t('navbar.pwreset')"><font-awesome-icon icon="unlock" class="fa-lg" /></b-nav-item>
            </div>
            <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.alerts.title')" :class="{active: routeName.startsWith('Reports')}" v-if="1 == 2">
              <template #button-content><font-awesome-icon icon="bell" class="fa-lg" /> <b-badge variant="success" v-if="$store.state.user.unreadalerts != 0">{{ $store.state.user.unreadalerts }}</b-badge></template>
              <b-dropdown-item v-for="alert in $store.state.user.alerts" :key="alert.id">
                <span v-if="alert.data.name == 'calendarinvitation'">{{ $t('navbar.alerts.calendarinvitation', {title: alert.data.title, date: $d(alert.data.eventstart, 'short'), username: alert.data.username}) }}</span>
                <span v-else-if="alert.data.name == 'calendarupdate'">{{ $t('navbar.alerts.calendarupdate', {title: alert.data.title, date: $d(alert.data.eventstart, 'short'), username: alert.data.username}) }}</span>
                &nbsp; 
                <b-badge pill variant="primary">{{moment(alert.timestamp).fromNow()}}</b-badge>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.user.title')" id="nav-user-button">
              <template #button-content>
                <b-avatar v-if="$store.state.user.profilepictureurl == false" size="sm" badge :badge-variant="$store.getters.getSocketStateColor" :text="$store.state.user.initials" variant="info"></b-avatar>
                <b-avatar v-else size="sm" badge :badge-variant="$store.getters.getSocketStateColor" :src="$store.state.user.profilepictureurl"></b-avatar>
                {{ $store.state.user.firstName }}
              </template>
              <b-dropdown-item :to="{ name: 'ChangePassword' }">{{ $t('navbar.user.changepassword') }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'UserCalendarSettings' }">{{ $t('navbar.user.calendarsettings') }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'UserSwitch' }" v-if="$store.state.user.id == 1">{{ $t('navbar.user.switch') }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'Logout' }" id="nav-logout-button">{{ $t('navbar.user.logoff') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-progress v-if="$store.getters.getLoadingState" :value="$store.getters.getLoadingState" max="23" height="7px" striped animated variant="info" class="progressbarloaddata"></b-progress>
    </div>
    <div v-else>
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand><img src="/assets/images/logo.png" alt="logo" width="100" height="33"></b-navbar-brand>
      </b-navbar>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'Navbar',
  data() {
    return {
      routeName: '',
    }
  },
  created: function () {
    this.moment = moment;
    this.moment.locale(this.$i18n.locale);
  },
  watch: {
    '$route.name'(newRoute) {
      this.routeName = newRoute
    }
  }
}
</script>

<style scoped>

.progressbarloaddata {
  border-radius: 0;
}

</style>
