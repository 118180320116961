import { createStore } from 'vuex'
import SocketioService from '@/services/socketio.service.js';
import i18n from '../i18n.js'

export default createStore({
  state: {
    socketio: {
      state: null,
      concurrentviewers: 0
    },
    baseurl: null,
    user: {
      id: null,
      firstName: '',
      lastName: '',
      initials: '',
      profilepictureurl: false,
      administrator: false,
      sessionUuid: null,
      unreadalerts: 0,
      alerts: {},
      ov1: false,
      ov3: false,
      smpasswordreset: false,
    },
    settings: {
      moduleMATRIXdisabled: false,
      moduleIHPdisabled: false,
      moduleCOMPETENTIEdisabled: false,
      moduleSMPWRESETdisabled: false,
      modulePOSdisabled: false,
      moduleCALENDARdisabled: false,
      ihpHideDomain: false
    },
    classes: {},
    courses: {},
    evaluationmoments: {},
    students: {},
    teachers: {},
    perspectives: {},
    goals: {},
    matrixparameters: {},
    hppe: {},
    parameters: {},
    levels: {},
    evaluationmomentscompetence: {},
    anchors: {},
    competenceparameters: {},
    products: {},
    coursegroups: {},
    messages: {},
    goalcategories: {},
    educationforms: {},
    onlineteachers: {},
    generalinfo: {},
    classtimes: {},
    subgoals: {},
    matrixtable: {},
    userroutes: {},
    rooms: {},
    teachergroups: {},
  },
  mutations: {
    setuserdata (state, object) {
      state.user.id = object.id
      state.user.firstName = object.firstName
      state.user.lastName = object.lastName
      state.user.administrator = object.administrator
      state.user.sessionUuid = object.sessionUuid
      state.user.initials = object.initials
      state.user.profilepictureurl = object.profilepictureurl
      if (state.user.id != null && state.user.sessionUuid != null) {
        SocketioService.setupSocketConnection(state.user.sessionUuid);
      }
      state.user.unreadalerts = object.unreadalerts
      state.user.alerts = object.alerts
      state.user.ov1 = object.ov1
      state.user.ov3 = object.ov3
      state.user.smpasswordreset = object.smpasswordreset
    },
    setuserloggedout (state) {
      state.user.id = null
      state.user.firstName = ''
      state.user.lastName = ''
      state.user.administrator = false
      state.user.sessionUuid = null
      state.user.initials = ''
      state.user.profilepictureurl = false
      state.user.unreadalerts = 0
      state.user.alerts = {}
      state.user.ov1 = false
      state.user.ov3 = false
      state.user.smpasswordreset = false
      SocketioService.disconnect();
      state.settings.moduleMATRIXdisabled = false
      state.settings.moduleIHPdisabled = false
      state.settings.moduleCOMPETENTIEdisabled = false
      state.settings.moduleSMPWRESETdisabled = false
      state.settings.modulePOSdisabled = false
      state.classes = {}
      state.courses = {}
      state.evaluationmoments = {}
      state.students = {}
      state.teachers = {}
      state.perspectives = {}
      state.goals = {}
      state.matrixparameters = {}
      state.hppe = {}
      state.parameters = {}
      state.levels = {}
      state.evaluationmomentscompetence = {}
      state.anchors = {}
      state.competenceparameters = {}
      state.products = {}
      state.coursegroups = {}
      state.messages = {}
      state.goalcategories = {}
      state.educationforms = {}
      state.onlineteachers = {}
      state.generalinfo = {}
      state.subgoals = {}
      state.matrixtable = {}
      state.userroutes = {}
      state.rooms = {}
      state.teachergroups = {}
    },
    setsettings (state, object) {
      state.settings.moduleMATRIXdisabled = object.moduleMATRIXdisabled
      state.settings.moduleIHPdisabled = object.moduleIHPdisabled
      state.settings.moduleCOMPETENTIEdisabled = object.moduleCOMPETENTIEdisabled
      state.settings.moduleSMPWRESETdisabled = object.moduleSMPWRESETdisabled
      state.settings.modulePOSdisabled = object.modulePOSdisabled
      state.settings.moduleCALENDARdisabled = object.moduleCALENDARdisabled
      state.settings.ihpHideDomain = object.ihpHideDomain
    },
    setclasses (state, object) {
      state.classes = object
    },
    setcourses (state, object) {
      state.courses = object
    },
    setevaluationmoments (state, object) {
      state.evaluationmoments = object
    },
    setstudents (state, object) {
      state.students = object
    },
    setteachers (state, object) {
      state.teachers = object
    },
    setperspectives (state, object) {
      state.perspectives = object
    },
    setgoals (state, object) {
      state.goals = object
    },
    setmatrixparameters (state, object) {
      state.matrixparameters = object
    },
    sethppe (state, object) {
      state.hppe = object
    },
    setparameters (state, object) {
      state.parameters = object
    },
    setlevels (state, object) {
      state.levels = object
    },
    setevaluationmomentscompetence (state, object) {
      state.evaluationmomentscompetence = object
    },
    setanchors (state, object) {
      state.anchors = object
    },
    setcompetenceparameters(state, object) {
      state.competenceparameters = object
    },
    setproducts(state, object) {
      state.products = object
    },
    setcoursegroups(state, object) {
      state.coursegroups = object
    },
    setmessages(state, object) {
      state.messages = object
    },
    setgoalcategories(state, object) {
      state.goalcategories = object
    },
    seteducationforms(state, object) {
      state.educationforms = object
    },
    setonlineteachers(state, object) {
      state.onlineteachers = object
    },
    setgeneralinfo(state, object) {
      state.generalinfo = object
    },
    setclasstimes(state, object) {
      state.classtimes = object
    },
    setsubgoals(state, object) {
      state.subgoals = object
    },
    setsocketiostate(state, value) {
      state.socketio.state = value
    },
    setsocketiocount(state, value) {
      state.socketio.concurrentviewers = value
    },
    setbaseurl(state, value) {
      state.baseurl = value
    },
    setmatrixtable(state, value) {
      state.matrixtable = value
    },
    setuserroutes(state, value) {
      state.userroutes = value
    },
    setrooms(state, value) {
      state.rooms = value
    },
    setteachergroups(state, value) {
      state.teachergroups = value
    },
    updatematrixtablerow(state, value) {
      var classgroup = null
      if (state.classes[value.classid] == undefined) {
        classgroup = 1
      } else {
        classgroup = state.classes[value.classid].classgroup
      }
      // loop over rows untill the one with the correct primarykey value
      for (const property in state.matrixtable) {
        if (state.matrixtable[property].primarykey == 'goal' + value.goal) {
          if ('parameter' in value && 'student' in value) {
            state.matrixtable[property]['student' + value.student] = value.parameter
          }
          if (value.rowvalue == 4 && classgroup != 3) {
            state.matrixtable[property]._rowVariant = 'success'
          } else if (value.rowvalue == 3 && classgroup != 3) {
            state.matrixtable[property]._rowVariant = 'info'
          } else if (value.rowvalue == 2 && classgroup != 3) {
            state.matrixtable[property]._rowVariant = 'warning'
          } else if (value.rowvalue == 1 && classgroup != 3) {
            state.matrixtable[property]._rowVariant = 'danger'
          }
        }
        if (state.matrixtable[property].primarykey == 'category' + value.categoryid) {
          for (const property2 in value.categorydata) {
            state.matrixtable[property]['student' + property2] = value.categorydata[property2].average
          }
        }
      }
    },
    updatematrixtablerowmodaldata(state, data) {
      state.matrixtable[data.index].modaldata = JSON.parse(JSON.stringify(data.data))
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getSocketStateColor: (state) => {
      if (state.socketio.state===true) {
        return 'success'
      }
      return 'danger'
    },
    getTeacherFirstName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.teachers[id] != undefined) {
        return state.teachers[id].firstName
      }
      return ''
    },
    getTeacherLastName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.teachers[id] != undefined) {
        return state.teachers[id].lastName
      }
      return ''
    },
    getTeacherFullName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.teachers[id] != undefined) {
        return state.teachers[id].fullName
      }
      return ''
    },
    getStudentFullName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      if (state.students[id] == undefined) {
        return ""
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        return state.students[id].firstName + ' ' + state.students[id].lastName
      }
      return ''
    },
    getStudentFirstName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      if (state.students[id] == undefined) {
        return ""
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        return state.students[id].firstName
      }
      return ''
    },
    getStudentLastName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      if (state.students[id] == undefined) {
        return ""
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        return state.students[id].lastName
      }
      return ''
    },
    getStudentCurrentYearInt: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        return state.students[id].currentYear
      }
      return ''
    },
    getStudentClassName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        if (state.classes[state.students[id].class] != undefined) {
          return state.classes[state.students[id].class].name
        }
      }
      return ''
    },
    getStudentClass: (state) => (id) => {
      if (id == null) {
        return null
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        if (state.classes[state.students[id].class] != undefined) {
          return state.classes[state.students[id].class].id
        }
      }
      return null
    },
    getStudentSchoolYearLong: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.students[id] != undefined) {
        return state.students[id].startYear + ' - ' + (parseInt(state.students[id].startYear) + 1)
      }
      return ''
    },
    getCoursegroupName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.coursegroups[id] != undefined) {
        return state.coursegroups[id].name
      }
      return ''
    },
    getGoalName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.goals[id] != undefined) {
        return state.goals[id].name
      }
      return ''
    },
    getGoalcategoryName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.goalcategories[id] != undefined) {
        return state.goalcategories[id].name
      }
      return ''
    },
    getGoalcategoryColor: (state) => (id) => {
      if (id == null) {
        return '#000000'
      }
      // validate if all data is there
      if (state.goalcategories[id] != undefined) {
        return state.goalcategories[id].color
      }
      return '#000000'
    },
    getGoalColor: (state) => (id) => {
      if (id == null) {
        return '#000000'
      }
      // validate if all data is there
      if (state.goals[id] != undefined) {
        return state.goals[id].color
      }
      return '#000000'
    },
    getSubgoalColor: (state) => (id) => {
      if (id == null) {
        return '#000000'
      }
      // validate if all data is there
      if (state.subgoals[id] != undefined) {
        return state.subgoals[id].color
      }
      return '#000000'
    },
    getPerspectiveName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.perspectives[id] != undefined) {
        return state.perspectives[id].name
      }
      return ''
    },
    getMatrixparameterName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.matrixparameters[id] != undefined) {
        return state.matrixparameters[id].name
      }
      return ''
    },
    getMatrixparameterNameShort: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.matrixparameters[id] != undefined) {
        return state.matrixparameters[id].shortName
      }
      return ''
    },
    getMatrixparameterValue: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.matrixparameters[id] != undefined) {
        return state.matrixparameters[id].value
      }
      return ''
    },
    getClassName: (state) => (id) => {
      if (id == null) {
        return ''
      }
      // validate if all data is there
      if (state.classes[id] != undefined) {
        return state.classes[id].name
      }
      return ''
    },
    selectTeachersNullable: state => {
      var output = []
      for (const property in state.teachers) {
        if (state.teachers[property].active) {
          output.push({ value: state.teachers[property].id, text: state.teachers[property].fullName })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: null, text: ' ' })
      return output
    },
    selectRooms: state => {
      var output = []
      for (const property in state.rooms) {
        if (!state.rooms[property].hidden) {
          output.push({ value: state.rooms[property].id, text: state.rooms[property].name, order: state.rooms[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectClassrooms: state => {
      var output = []
      for (const property in state.rooms) {
        if (!state.rooms[property].hidden && state.rooms[property].classroom) {
          output.push({ value: state.rooms[property].id, text: state.rooms[property].name })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectClasses: state => {
      var output = []
      for (const property in state.classes) {
        if (!state.classes[property].hidden) {
          output.push({ value: state.classes[property].id, text: state.classes[property].name, order: state.classes[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectClassesFiltered: state => {
      var output = []
      for (const property in state.classes) {
        if (!state.classes[property].hidden && state.classes[property].access) {
          output.push({ value: state.classes[property].id, text: state.classes[property].name, order: state.classes[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectClassesFilteredOV12: state => {
      var output = []
      for (const property in state.classes) {
        if (!state.classes[property].hidden && state.classes[property].access && ( state.classes[property].classgroup == 1 || state.classes[property].classgroup == 2)) {
          output.push({ value: state.classes[property].id, text: state.classes[property].name, order: state.classes[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectClassesFilteredIndicator: (state) => {
      var output = []
      for (const property in state.classes) {
        if (!state.classes[property].hidden && state.classes[property].access) {
          output.push({ value: state.classes[property].id, text: state.classes[property].name, order: state.classes[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.class'), disabled: true })
      return output
    },
    selectClassesFilteredOV3Indicator: state => {
      var output = []
      for (const property in state.classes) {
        if (!state.classes[property].hidden && state.classes[property].access && state.classes[property].classgroup == 3) {
          output.push({ value: state.classes[property].id, text: state.classes[property].name, order: state.classes[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.class'), disabled: true })
      return output
    },
    selectClassesFilteredOV12Indicator: state => {
      var output = []
      for (const property in state.classes) {
        if (!state.classes[property].hidden && state.classes[property].access && ( state.classes[property].classgroup == 1 || state.classes[property].classgroup == 2)) {
          output.push({ value: state.classes[property].id, text: state.classes[property].name, order: state.classes[property].order })
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.class'), disabled: true })
      return output
    },
    selectStudentsFiltered: state => {
      var output = []
      for (const property in state.students) {
        output.push({ value: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName })
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectClasstimes: state => {
      var output = []
      for (const property in state.classtimes) {
        if (!state.classtimes[property].hidden) {
          output.push({ value: state.classtimes[property].id, text: state.classtimes[property].name })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectMatrixparameters: (state) => (id) => {
      var output = []
      if (id == null) {
        return output
      }
      if (state.classes[id] == undefined) {
        return null
      }
      for (const property in state.matrixparameters) {
        if (state.classes[id].classgroup == 1 || state.classes[id].classgroup == 2) {
          if (state.matrixparameters[property].ov3 != true) {
            output.push({ value: state.matrixparameters[property].id, text: state.matrixparameters[property].shortName, order: state.matrixparameters[property].order })
          }
        } else {
          output.push({ value: state.matrixparameters[property].id, text: state.matrixparameters[property].shortName, order: state.matrixparameters[property].order })
        }
      }
      if (state.classes[id].classgroup == 1 || state.classes[id].classgroup == 2) {
        output.sort(function(a, b) {
          var textA = a.text.toUpperCase();
          var textB = b.text.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      } else {
        output.sort((a, b) => (a.order > b.order) ? 1 : -1)
      }
      return output
    },
    selectMatrixparametersLong: (state) => (id) => {
      var output = []
      if (id == null) {
        return output
      }
      if (state.classes[id] == undefined) {
        return null
      }
      for (const property in state.matrixparameters) {
        if (state.classes[id].classgroup == 1 || state.classes[id].classgroup == 2) {
          if (state.matrixparameters[property].ov3 != true) {
            output.push({ value: state.matrixparameters[property].id, text: state.matrixparameters[property].name, order: state.matrixparameters[property].order })
          }
        } else {
          output.push({ value: state.matrixparameters[property].id, text: state.matrixparameters[property].name, order: state.matrixparameters[property].order })
        }
      }
      if (state.classes[id].classgroup == 1 || state.classes[id].classgroup == 2) {
        output.sort(function(a, b) {
          var textA = a.text.toUpperCase();
          var textB = b.text.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      } else {
        output.sort((a, b) => (a.order > b.order) ? 1 : -1)
      }
      return output
    },
    selectHppe: state => {
      var output = []
      for (const property in state.hppe) {
        output.push({ value: state.hppe[property].id, text: state.hppe[property].name })
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectHppeEmpty0ClearableEmpty: state => {
      var output = []
      for (const property in state.hppe) {
        output.push({ value: state.hppe[property].id, text: state.hppe[property].name })
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: ' ' })
      return output
    },
    selectEvaluationmoments: state => {
      var output = []
      for (const property in state.evaluationmoments) {
        if (!state.evaluationmoments[property].hidden) {
          output.push({ value: state.evaluationmoments[property].id, text: state.evaluationmoments[property].name })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectEvaluationmomentsIndicator: state => {
      var output = []
      for (const property in state.evaluationmoments) {
        if (!state.evaluationmoments[property].hidden) {
          output.push({ value: state.evaluationmoments[property].id, text: state.evaluationmoments[property].name })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.evaluationmoment'), disabled: true })
      return output
    },
    selectevaluationmomentscompetence: state => {
      var output = []
      for (const property in state.evaluationmomentscompetence) {
        output.push({ value: state.evaluationmomentscompetence[property].id, text: state.evaluationmomentscompetence[property].name })
      }
      output.unshift({ value: 0, text: i18n.global.t('select.evaluationmomentcompetence'), disabled: true })
      return output
    },
    selectCourses: state => {
      var output = []
      for (const property in state.courses) {
        if (!state.courses[property].hidden) {
          output.push({ value: state.courses[property].id, text: state.courses[property].name })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    selectDomains: state => {
      var output = []
      for (const property in state.parameters) {
        if (state.parameters[property].id != 4) {
          output.push({ value: state.parameters[property].id, text: state.parameters[property].name })
        }
      }
      return output
    },
    selectDomainsIndicator: state => {
      var output = []
      for (const property in state.parameters) {
        if (state.parameters[property].id != 4) {
          output.push({ value: state.parameters[property].id, text: state.parameters[property].name })
        }
      }
      output.unshift({ value: 0, text: i18n.global.t('select.domain'), disabled: true })
      return output
    },
    selectDomainsAll: state => {
      var output = []
      for (const property in state.parameters) {
        output.push({ value: state.parameters[property].id, text: state.parameters[property].name })
      }
      return output
    },
    selectLevels: state => {
      var output = []
      for (const property in state.levels) {
        output.push({ value: state.levels[property].id, text: state.levels[property].name })
      }
      return output
    },
    selectGoals: state => {
      var output = []
      for (const property in state.goals) {
        output.push({ value: state.goals[property].id, text: state.goals[property].name })
      }
      return output
    },
    selectGoalsTextFiltered: (state) => (filtertext) => {
      var output = []
      for (const property in state.goals) {
        if (state.goals[property].name.toUpperCase().includes(filtertext.toUpperCase())) {
          output.push({ value: state.goals[property].id, text: state.goals[property].name })
        }
      }
      return output
    },
    selectPerspectives: (state) => (id) => {
      if (id == null) {
        return []
      }
      // fetch education form from class
      var educationform = state.classes[id].classgroup
      var output = []
      for (const property in state.perspectives) {
        if (state.perspectives[property].educationform == educationform) {
          output.push({ value: state.perspectives[property].id, text: state.perspectives[property].name })
        }
      }
      return output
    },
    selectPerspectivesIndicator: (state) => (id) => {
      var output = []
      if (id == null) {
        for (const property in state.perspectives) {
          output.push({ value: state.perspectives[property].id, text: state.perspectives[property].name })
        }
        output.unshift({ value: 0, text: i18n.global.t('select.perspective'), disabled: true })
        return output
      }
      if (state.classes[id] == undefined) {
        return output
      }
      // if educationform = 2 change it to 1.
      var educationform = state.classes[id].classgroup
      if (educationform == 2) {
        educationform = 1
      }
      // fetch education form from class
      for (const property in state.perspectives) {
        if (state.perspectives[property].educationform == educationform) {
          output.push({ value: state.perspectives[property].id, text: state.perspectives[property].name })
        }
      }
      output.unshift({ value: 0, text: i18n.global.t('select.perspective'), disabled: true })
      return output
    },
    selectPerspectivesAll: state => {
      var output = []
      for (const property in state.perspectives) {
        output.push({ value: state.perspectives[property].id, text: state.perspectives[property].name })
      }
      return output
    },
    selectCompetenceparameters: state => {
      var output = []
      for (const property in state.competenceparameters) {
        output.push({ value: state.competenceparameters[property].id, text: state.competenceparameters[property].name })
      }
      return output
    },
    selectCompetenceparametersClearableEmpty: state => {
      var output = []
      for (const property in state.competenceparameters) {
        output.push({ value: state.competenceparameters[property].id, text: state.competenceparameters[property].name })
      }
      output.unshift({ value: 0, text: ' ' })
      return output
    },
    selectEducationforms: state => {
      var output = []
      for (const property in state.educationforms) {
        output.push({ value: state.educationforms[property].id, text: state.educationforms[property].name })
      }
      return output
    },
    selectCoursegroups: state => {
      var output = []
      for (const property in state.coursegroups) {
        output.push({ value: state.coursegroups[property].id, text: state.coursegroups[property].name })
      }
      return output
    },
    selectGoalcategories: state => {
      var output = []
      for (const property in state.goalcategories) {
        output.push({ value: state.goalcategories[property].id, text: state.goalcategories[property].name })
      }
      return output
    },
    selectCompetence: state => {
      var output = []
      for (const property in state.anchors) {
        output.push({ value: state.anchors[property].id, text: state.anchors[property].name })
      }
      return output
    },
    getClassgroupByClassId: (state) => (id) => {
      if (id == null) {
        return null
      }
      if (state.classes[id] == undefined) {
        return null
      }
      return state.classes[id].classgroup
    },
    getClassNameByClassId: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.classes[id] == undefined) {
        return ""
      }
      return state.classes[id].name
    },
    getTeachergroupNameByClassId: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachergroups[id] == undefined) {
        return ""
      }
      return state.teachergroups[id].name
    },
    getTeachergroupNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachergroups[id] == undefined) {
        return ""
      }
      return state.teachergroups[id].name
    },
    getStudentFirstnameByStudentId: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.students[id] == undefined) {
        return ""
      }
      return state.students[id].firstName
    },
    getLevelNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.levels[id] == undefined) {
        return ""
      }
      return state.levels[id].name
    },
    getCourseNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.courses[id] == undefined) {
        return ""
      }
      return state.courses[id].name
    },
    getParameterTypeNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.parameters[id] == undefined) {
        return ""
      }
      return state.parameters[id].name
    },
    getParameterNameByTypeAndId: (state) => (type, id) => {
      if (id == null) {
        return ""
      }
      if (state.parameters[type] == undefined) {
        return ""
      }
      if (state.parameters[type].parameters[id] == undefined) {
        return ""
      }
      return state.parameters[type].parameters[id].name
    },
    getSubgoalNameByGoalAndId: (state) => (goal, id) => {
      if (id == null) {
        return ""
      }
      if (state.goals[goal] == undefined) {
        return ""
      }
      if (state.goals[goal].subgoals[id] == undefined) {
        return ""
      }
      return state.goals[goal].subgoals[id].name
    },
    getCourseImageUrl: (state) => (course, classgroup) => {
      if (course == null || classgroup == null) {
        return ""
      }
      if (state.courses[course] == undefined) {
        return ""
      }
      if (state.courses[course].iconsEducationforms[classgroup] == undefined) {
        return ""
      }
      return state.courses[course].iconsEducationforms[classgroup]
    },
    getSubgoalNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.subgoals[id] == undefined) {
        return ""
      }
      var goal = state.subgoals[id].goal
      if (state.goals[goal] == undefined) {
        return ""
      }
      if (state.goals[goal].subgoals[id] == undefined) {
        return ""
      }
      return state.goals[goal].subgoals[id].name
    },
    getClasstimeById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.classtimes[id] == undefined) {
        return ""
      }
      return state.classtimes[id].name
    },
    getRoomName: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.rooms[id] == undefined) {
        return ""
      }
      return state.rooms[id].name
    },
    getTeacherNameAndPrefixById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachers[id] == undefined) {
        return ""
      }
      return state.teachers[id].prefix + ' ' + state.teachers[id].firstName
    },
    getTeacherFullNameAndPrefixById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachers[id] == undefined) {
        return ""
      }
      return state.teachers[id].firstName + ' ' + state.teachers[id].lastName
    },
    getTeacherNameAndById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachers[id] == undefined) {
        return ""
      }
      return state.teachers[id].firstName
    },
    getTeacherNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachers[id] == undefined) {
        return ""
      }
      return state.teachers[id].firstName
    },
    getTeacherLastNameById: (state) => (id) => {
      if (id == null) {
        return ""
      }
      if (state.teachers[id] == undefined) {
        return ""
      }
      return state.teachers[id].lastName
    },
    getStudentClassidByStudentId: (state) => (id) => {
      if (id == null) {
        return null
      }
      if (state.students[id] == undefined) {
        return null
      }
      return state.students[id].class
    },
    getSelectCoursesByClassId: (state) => (id) => {
      if (id == null) {
        return []
      }
      var output = []
      for (const property in state.courses) {
        if (!state.courses[property].hidden) {
          for (const property2 in state.courses[property].classes) {
            if (state.courses[property].classes[property2] == id) {
              output.push({ value: state.courses[property].id, text: state.courses[property].name, order: state.courses[property].order })
            }
          }
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    getSelectCoursesByClassIdEmpty0ClearableEmpty: (state) => (id) => {
      if (id == null) {
        return []
      }
      var output = []
      for (const property in state.courses) {
        if (!state.courses[property].hidden) {
          if (state.courses[property].classes.includes(id) && state.courses[property].teachers.includes(state.user.id)) {
            output.push({ value: state.courses[property].id, text: state.courses[property].name, order: state.courses[property].order })
          }
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: ' ' })
      return output
    },
    getSelectCoursesByClassIdEmpty0: (state) => (id) => {
      if (id == null) {
        return []
      }
      var output = []
      for (const property in state.courses) {
        if (!state.courses[property].hidden) {
          if (state.courses[property].classes.includes(id) && state.courses[property].teachers.includes(state.user.id)) {
            output.push({ value: state.courses[property].id, text: state.courses[property].name, order: state.courses[property].order })
          }
        }
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.course'), disabled: true })
      return output
    },
    getSelectCoursesByClassIdsEmpty0: (state) => (id) => {
      if (id == null) {
        return []
      }
      var outputcleanup = []
      var output = []
      for (const property5 in id) {
        for (const property in state.courses) {
          if (!state.courses[property].hidden) {
            if (state.courses[property].classes.includes(id[property5]) && state.courses[property].teachers.includes(state.user.id)) {
              outputcleanup[state.courses[property].id] = { value: state.courses[property].id, text: state.courses[property].name, order: state.courses[property].order }
            }
          }
        }
      }
      for (const property6 in outputcleanup) {
        output.push({ value: outputcleanup[property6].value, text: outputcleanup[property6].text })
      }
      output.sort(function(a, b) {
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.course'), disabled: true })
      return output
    },
    getSelectTeachersByClassId: (state) => (id) => {
      if (id == null) {
        return []
      }
      var teachers = []
      for (const property in state.teachers) {
        if (state.teachers[property].active) {
          if (state.teachers[property].classes[id].value == true) {
            teachers.push({ value: state.teachers[property].id, text: state.teachers[property].firstName + ' ' + state.teachers[property].lastName })
          }
        }
      }
      teachers.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      teachers.unshift({ value: 0, text: i18n.global.t('select.teacher'), disabled: true })
      return teachers
    },
    getSelectTeachers: (state) => {
      var teachers = []
      for (const property in state.teachers) {
        if (state.teachers[property].active) {
          teachers.push({ value: state.teachers[property].id, text: state.teachers[property].firstName + ' ' + state.teachers[property].lastName })
        }
      }
      teachers.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      teachers.unshift({ value: 0, text: i18n.global.t('select.teacher'), disabled: true })
      return teachers
    },
    getSelectStudentsByClassId: (state) => (id) => {
      if (id == null) {
        return []
      }
      var students = []
      for (const property in state.students) {
        if (id in state.students[property].classes) {
          students.push({ value: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName })
        }
      }
      students.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return students
    },
    getSelectStudentsByClassIdIndicator: (state) => (id) => {
      if (id == null) {
        return []
      }
      var output = []
      for (const property in state.students) {
        if (id in state.students[property].classes) {
          output.push({ value: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName })
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.student'), disabled: true })
      return output
    },
    getSelectStudentsByClassesIdIndicator: (state) => (id) => {
      if (id == null || id == [] || !Array.isArray(id)) {
        return []
      }
      var output = []
      for (const property2 in id) {
        for (const property in state.students) {
          if (id[property2] in state.students[property].classes) {
            if (state.classes[id[property2]] != undefined) {
              output.push({ value: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName + ' (' + state.classes[id[property2]].name + ')' })
            } else {
              output.push({ value: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName })
            }
          }
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      output.unshift({ value: 0, text: i18n.global.t('select.student'), disabled: true })
      return output
    },
    getSelectStudentsAndTeachersByString: (state) => (searchstring) => {
      if (searchstring == null) {
        return []
      }
      var searchstringupper = searchstring.toUpperCase()
      var output = []
      for (const property in state.students) {
        if (state.students[property].firstName.toUpperCase().includes(searchstringupper) || state.students[property].lastName.toUpperCase().includes(searchstringupper)) {
          if (state.classes[state.students[property].class] != undefined ) {
            output.push({ value: { type: 'student', id: state.students[property].id}, text: state.students[property].firstName + ' ' + state.students[property].lastName + ' ( ' + state.classes[state.students[property].class].name + ' )' })
          }
        }
      }
      for (const property in state.teachers) {
        if (state.teachers[property].active) {
          if (state.teachers[property].firstName.toUpperCase().includes(searchstringupper) || state.teachers[property].lastName.toUpperCase().includes(searchstringupper)) {
            output.push({ value: { type: 'teacher', id: state.teachers[property].id}, text: state.teachers[property].firstName + ' ' + state.teachers[property].lastName })
          }
        }
      }
      output.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return output
    },
    getParametersByDomain: (state) => (id) => {
      var parameters = []
      parameters.push({ value: 0, text: ' ' })
      if (!(Object.keys(state.parameters).length === 0)) {
        for (const property in state.parameters[id].parameters) {
          if (!state.parameters[id].parameters[property].hidden) {
            parameters.push({ value: state.parameters[id].parameters[property].id, text: state.parameters[id].parameters[property].name })
          }
        }
      }
      parameters.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return parameters
    },
    selectPerspectivesFilteredClassMatrix: (state) => (id) => {
      var output = []
      if (!(Object.keys(state.classes).length === 0)) {
        var eductaionform = state.classes[id].classgroup
        if (eductaionform == 2) {
          eductaionform = 1
        }
        if (!(Object.keys(state.perspectives).length === 0)) {
          for (const property in state.perspectives) {
            if (state.perspectives[property].educationform == eductaionform) {
              // check if restrictions are enabled
              if (state.perspectives[property].classRestricted && state.perspectives[property].classes[id].value != true) {
                // do not add it to the list
              } else {
                output.push({ value: String(state.perspectives[property].id), text: state.perspectives[property].name })
              }
            }
          }
        }
      }
      output.unshift({ value: null, text: i18n.global.t('select.nofilter') })
      return output
    },
    getClassesSettings: (state) => {
      var classes = []
      var tmpobj = {}
      for (const property in state.classes) {
        if (!state.classes[property].hidden) {
          tmpobj = {}
          tmpobj.id = state.classes[property].id
          tmpobj.name = state.classes[property].name
          tmpobj.classgroup = state.classes[property].classgroup
          tmpobj.icon = state.classes[property].id
          tmpobj.hascourse = false
          for (const property2 in state.classes[property].courses) {
            if (state.classes[property].courses[property2].value == true) {
              tmpobj.hascourse = true
              break
            }
          }
          tmpobj.hasstudents = state.classes[property].hasstudents
          tmpobj.color = state.classes[property].calendarcolor
          tmpobj.data = state.classes[property]
          classes.push(tmpobj)
        }
      }
      return classes
    },
    getClastimesSettings: (state) => {
      var classtimes = []
      for (const property in state.classtimes) {
        if (!state.classtimes[property].hidden) {
          classtimes.push({ id: state.classtimes[property].id, name: state.classtimes[property].name, start: state.classtimes[property].start, end: state.classtimes[property].end, data: state.classtimes[property] })
        }
      }
      return classtimes
    },
    getRoomsSettings: (state) => {
      var rooms = []
      for (const property in state.rooms) {
        if (!state.rooms[property].hidden) {
          rooms.push({ id: state.rooms[property].id, name: state.rooms[property].name, classroom: state.rooms[property].classroom, showInResourceCalendar: state.rooms[property].showInResourceCalendar, data: state.rooms[property] })
        }
      }
      return rooms
    },
    getTeachergroupsSettings: (state) => {
      var output = []
      for (const property in state.teachergroups) {
        if (!state.teachergroups[property].hidden) {
          output.push({ id: state.teachergroups[property].id, name: state.teachergroups[property].name, data: state.teachergroups[property] })
        }
      }
      return output
    },
    getEvaluationmomentsSettings: (state) => {
      var output = []
      for (const property in state.evaluationmoments) {
        output.push({ name: state.evaluationmoments[property].name, data: state.evaluationmoments[property] })
      }
      return output
    },
    getCompitencesSettings: (state) => {
      var output = []
      for (const property in state.anchors) {
        if (!state.anchors[property].hidden) {
          output.push({ id: state.anchors[property].id, name: state.anchors[property].name, color: state.anchors[property].color, data: state.anchors[property] })
        }
      }
      return output
    },
    getPerspectivesSettings: (state) => {
      var output = []
      for (const property in state.perspectives) {
        if(state.educationforms[state.perspectives[property].educationform] != undefined) {
          output.push({ id: state.perspectives[property].id, name: state.perspectives[property].name, educationforms: state.educationforms[state.perspectives[property].educationform].name, classRestricted: state.perspectives[property].classRestricted, courseRestricted: state.perspectives[property].courseRestricted, data: state.perspectives[property] })

        }
      }
      return output
    },
    getCompitenceanchorsSettings: (state) => {
      var output = []
      for (const property in state.anchors) {
        if (!state.anchors[property].hidden) {
          for (const property2 in state.anchors[property].anchors) {
            output.push({ id: state.anchors[property].anchors[property2].id, name: state.anchors[property].anchors[property2].name, competence: state.anchors[property].name, period: state.anchors[property].anchors[property2].period, goal: state.anchors[property].anchors[property2].goal, data: state.anchors[property].anchors[property2] })
          }
        }
      }
      return output
    },
    getCoursesSettings: (state) => {
      var courses = []
      var perspective = ''
      for (const property in state.courses) {
        if (!state.courses[property].hidden) {
          if (state.perspectives[state.courses[property].perspective] != undefined) {
            perspective = state.perspectives[state.courses[property].perspective].name
          } else {
            perspective = ''
          }
          courses.push({ id: state.courses[property].id, name: state.courses[property].name, color: state.courses[property].color, coursegroup: state.coursegroups[state.courses[property].courseGroup].name, perspective: perspective, data: state.courses[property] })
        }
      }
      return courses
    },
    getTeachersSettings: (state) => {
      var teachers = []
      for (const property in state.teachers) {
        teachers.push({ id: state.teachers[property].id, firstname: state.teachers[property].firstName, lastname: state.teachers[property].lastName, admin: state.teachers[property].admin, readonly: state.teachers[property].readonly, active: state.teachers[property].active, locked: state.teachers[property].locked, data: state.teachers[property] })
      }
      return teachers
    },
    getSubgoalsSettings: (state) => {
      var subgoals = []
      for (const property in state.goals) {
        for (const property2 in state.goals[property].subgoals) {
          if (!state.goals[property].subgoals[property2].hidden) {
            subgoals.push({ id: state.goals[property].subgoals[property2].id, subgoal: state.goals[property].subgoals[property2].name, domain: state.parameters[state.goals[property].subgoals[property2].evaluationType].name, perspectives: state.perspectives[state.goals[property].toekomstperspectief].name, category: state.goalcategories[state.goals[property].goalcategory].name, goal: state.goals[property].name, color: state.goals[property].subgoals[property2].color })
          }
        }
      }
      return subgoals
    },
    getGoalsSettings: (state) => {
      var output = []
      for (const property in state.goals) {
        if (state.goalcategories[state.goals[property].goalcategory] != undefined) {
          output.push({ id: state.goals[property].id, perspective: state.perspectives[state.goals[property].toekomstperspectief].name, category: state.goalcategories[state.goals[property].goalcategory].name, goal: state.goals[property].name, color: state.goals[property].color, courserestricted: state.goals[property].courseRestricted, data: state.goals[property] })
        }
      }
      return output
    },
    getStudentsSettings: (state, getters) => {
      var students = []
      for (const property in state.students) {
        students.push({ id: state.students[property].id, firstName: state.students[property].firstName, lastName: state.students[property].lastName, class: getters.getClassNameByClassId(state.students[property].class), data: state.students[property] })
      }
      return students
    },
    getStudentSubaccountsSettings: (state) => (id) => {
      if (id == null) {
        return []
      }
      if (state.students[id] == undefined) {
        return []
      }
      var output = []
      for (const property in state.students[id].subaccounts) {
        output.push({ id: state.students[id].subaccounts[property].id, firstname: state.students[id].subaccounts[property].firstname, lastname: state.students[id].subaccounts[property].lastname, type: state.students[id].subaccounts[property].type, setup: state.students[id].subaccounts[property].setup })
      }
      return output
    },
    getStudentSubclassesSettings: (state) => (id) => {
      if (id == null) {
        return []
      }
      if (state.students[id] == undefined) {
        return []
      }
      var output = []
      for (const property in state.students[id].classes) {
        output.push({ id: state.students[id].classes[property].id, primary: state.students[id].classes[property].primary, classid: state.students[id].classes[property].id })
      }
      return output
    },
    getCategoriesSettings: (state) => {
      var goalcategories = []
      for (const property in state.goalcategories) {
        goalcategories.push({ id: state.goalcategories[property].id, name: state.goalcategories[property].name, hiddeningraphs: state.goalcategories[property].hiddeningraphs, courserestricted: state.goalcategories[property].courseRestricted, color: state.goalcategories[property].color, data: state.goalcategories[property] })
      }
      return goalcategories
    },
    getStudentsPwreset: (state) => {
      var output = []
      for (const property in state.students) {
        if (state.students[property].smartschoolUsername == null ) {
          //do nothing
        } else if (state.classes[state.students[property].class] == undefined ) {
          //do nothing
        } else {
          output.push({ start: state.students[property].id, firstName: state.students[property].firstName, lastName: state.students[property].lastName, smusername: state.students[property].smartschoolUsername, class: state.classes[state.students[property].class].name })
        }
      }
      return output
    },
    getParametersSettings: (state) => {
      var parameters = []
      for (const property in state.parameters) {
        for (const property2 in state.parameters[property].parameters) {
          if (!state.parameters[property].parameters[property2].hidden) {
            parameters.push({ id: state.parameters[property].parameters[property2].id, name: state.parameters[property].parameters[property2].name, domain: state.parameters[property].name, data: state.parameters[property].parameters[property2] })
          }
        }
      }
      return parameters
    },
    getPosProductSettings: (state) => {
      var products = []
      for (const property in state.products) {
        if (state.products[property].archived != true) {
          products.push({ start: state.products[property].id, name: state.products[property].name, price: state.products[property].price, picture: state.products[property].id });
        }
      }
      return products
    },
    getSelectSubgoalsByGoal: (state) => (id) => {
      var subgoals = []
      for (const property in state.goals[id].subgoals) {
        if (!state.goals[id].subgoals[property].hidden) {
          subgoals.push({ value: state.goals[id].subgoals[property].id, text: state.goals[id].subgoals[property].name })
        }
      }
      subgoals.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return subgoals
    },
    getNextStudentsByClassIdAndStudentId: (state) => (parameters) => {
      if (parameters == null || parameters.classid == null || parameters.student== null) {
        return 0
      }
      var students = []
      for (const property in state.students) {
        if (state.students[property].class == parameters.classid) {
          students.push({ id: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName })
        }
      }
      students.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      // look for index
      var currentindex = null
      for (const property in students) {
        if (students[property].id == parameters.student) {
          currentindex = property
        }
      }
      // check if currentindex == max number and if so return the first id
      if (currentindex == (students.length -1)) {
        return students[0].id
      }
      // retrun the next id
      var nextid = parseInt(currentindex) + 1
      return students[nextid].id
    },
    getPreviousStudentsByClassIdAndStudentId: (state) => (parameters) => {
      if (parameters == null || parameters.classid == null || parameters.student== null) {
        return 0
      }
      var students = []
      for (const property in state.students) {
        if (state.students[property].class == parameters.classid) {
          students.push({ id: state.students[property].id, text: state.students[property].firstName + ' ' + state.students[property].lastName })
        }
      }
      students.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      // look for index
      var currentindex = null
      for (const property in students) {
        if (students[property].id == parameters.student) {
          currentindex = property
        }
      }
      // check if currentindex == 0 number and if so return the last id
      if (currentindex == 0) {
        return students[(students.length -1)].id
      }
      // retrun the previous id
      var nextid = parseInt(currentindex) - 1
      return students[nextid].id
    },
    getLoadingState: (state) => {
      if(state.user.id == null) {
        return false
      }
      var count = 0
      if(Object.keys(state.classes).length === 0) {
        count++
      }
      if(Object.keys(state.courses).length === 0) {
        count++
      }
      if(Object.keys(state.evaluationmoments).length === 0) {
        count++
      }
      if(Object.keys(state.students).length === 0) {
        count++
      }
      if(Object.keys(state.teachers).length === 0) {
        count++
      }
      if(Object.keys(state.perspectives).length === 0) {
        count++
      }
      if(Object.keys(state.goals).length === 0) {
        count++
      }
      if(Object.keys(state.matrixparameters).length === 0) {
        count++
      }
      if(Object.keys(state.parameters).length === 0) {
        count++
      }
      if(Object.keys(state.classtimes).length === 0) {
        count++
      }
      if(Object.keys(state.hppe).length === 0) {
        count++
      }
      if(Object.keys(state.levels).length === 0) {
        count++
      }
      if(Object.keys(state.evaluationmomentscompetence).length === 0) {
        count++
      }
      if(Object.keys(state.anchors).length === 0) {
        count++
      }
      if(Object.keys(state.competenceparameters).length === 0) {
        count++
      }
      if(Object.keys(state.products).length === 0) {
        count++
      }
      if(Object.keys(state.coursegroups).length === 0) {
        count++
      }
      if(Object.keys(state.messages).length === 0) {
        count++
      }
      if(Object.keys(state.goalcategories).length === 0) {
        count++
      }
      if(Object.keys(state.educationforms).length === 0) {
        count++
      }
      if(Object.keys(state.generalinfo).length === 0) {
        count++
      }
      if(Object.keys(state.subgoals).length === 0) {
        count++
      }
      if(count == 0) {
        return false
      }
      return count
    },
  },
  strict: process.env.NODE_ENV !== 'production'
})
