<template>
  <div class="notfound">
    <br>
    <br>
    <br>
    <b-row>
      <b-col>
        <div class="text-center">
          <h1>{{ $t('notfound.title') }}</h1>
          <h2>{{ $t('notfound.subtitle') }}</h2>
          <p>{{ $t('notfound.details') }}</p>
          <b-button :to="{ name: 'Home' }" variant="primary">{{ $t('notfound.button') }}</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
