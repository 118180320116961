import { io } from 'socket.io-client';

import router from '../router'
import store from '../store'
import i18n from '../i18n.js'
import { app } from "../main";
import { getData } from '../api'

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection(userUuid) {

    var start;

    store.commit('setsocketiostate', false)

    // if dev use http otherwise use https

    if (process.env.VUE_APP_VERSION == 'dev') {

      start = 'http://';

    } else {

      start = 'https://';

    }

    this.socket = io(start + store.state.baseurl, {
      auth: {
        token: userUuid
      },
      transports: ["websocket", "polling"]
    });

    //this.socket.on('private', (data) => {
    //  console.log(data);
    //});

    this.socket.on('broadcast', (json) => {

      var data = JSON.parse(json);

      app._instance.ctx._bv__toast.toast( data.data.message, {
        autoHideDelay: 2500,
        variant: data.data.type
      });

    });

    this.socket.on('users-routes', (json) => {

      var data = JSON.parse(json);

      if (data.event == 'user-routes') {

        // put data in store in the future

        store.commit('setuserroutes', data.data);

      } //else {

        //console.log(data);

      //}

    });

    this.socket.on('route', (json) => {

      var data = JSON.parse(json);

      if (data.event == 'count') {

        store.commit('setsocketiocount', data.number);

      } else if (data.event == 'joined') {

        if (app._instance && app._instance.ctx && app._instance.ctx._bv__toast) {
          app._instance.ctx._bv__toast.toast( i18n.global.t('pagewatching.message', { firstName: store.getters.getTeacherNameAndById(data.user)}), {
            title: i18n.global.t('pagewatching.title'),
            autoHideDelay: 2500,
            variant: "info"
          });
        }

      } else if (data.event == 'matrixrow') {

        store.commit('updatematrixtablerow', data.data)

        if (data.data.user != store.state.user.id) {

          app._instance.ctx._bv__toast.toast( i18n.global.t('matrixupdate.message', { firstName: store.getters.getTeacherNameAndById(data.data.user)}), {
            title: i18n.global.t('matrixupdate.title'),
            autoHideDelay: 2500,
            variant: "info"
          });

        }

      }

    });

    this.socket.on('reloaddata', (json) => {

      var data = JSON.parse(json);

      if (data.data.endpoints != undefined) {

        for (const endpoint in data.data.endpoints) {

          switch (data.data.endpoints[endpoint]) {
            case 'generalinfo':
              getData('generalinfo', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setgeneralinfo', responseData.generalinfo)
                }
              })
              break;
            case 'settings':
              getData('settings', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setsettings', responseData.settings)
                }
              })
              break;
            case 'evaluationmoments':
              getData('evaluationmoments', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setevaluationmoments', responseData.evaluationmoments)
                }
              })
              break;
            case 'perspectives':
              getData('perspectives', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setperspectives', responseData.perspectives)
                }
              })
              break;
            case 'matrixparameters':
              getData('matrixparameters', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setmatrixparameters', responseData.matrixparameters)
                }
              })
              break;
            case 'evaluationmomentscompetence':
              getData('evaluationmomentscompetence', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setevaluationmomentscompetence', responseData.evaluationmomentscompetence)
                }
              })
              break;
            case 'competenceparameters':
              getData('competenceparameters', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setcompetenceparameters', responseData.competenceparameters)
                }
              })
              break;
            case 'coursegroups':
              getData('coursegroups', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setcoursegroups', responseData.coursegroups)
                }
              })
              break;
            case 'goalcategories':
              getData('goalcategories', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setgoalcategories', responseData.goalcategories)
                }
              })
              break;
            case 'educationforms':
              getData('educationforms', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('seteducationforms', responseData.educationforms)
                }
              })
              break;
            case 'messages':
              getData('messages', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setmessages', responseData.messages)
                }
              })
              break;
            case 'hppe':
              getData('hppe', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('sethppe', responseData.hppe)
                }
              })
              break;
            case 'levels':
              getData('levels', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setlevels', responseData.levels)
                }
              })
              break;
            case 'anchors':
              getData('anchors', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setanchors', responseData.anchors)
                }
              })
              break;
            case 'parameters':
              getData('parameters', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setparameters', responseData.parameters)
                }
              })
              break;
            case 'goals':
              getData('goals', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setgoals', responseData.goals)
                }
              })
              break;
            case 'students':
              getData('students', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setstudents', responseData.students)
                }
              })
              break;
            case 'teachers':
              getData('teachers', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setteachers', responseData.teachers)
                }
              })
              break;
            case 'classes':
              getData('classes', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setclasses', responseData.classes)
                }
              })
              break;
            case 'courses':
              getData('courses', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setcourses', responseData.courses)
                }
              })
              break;
            case 'products':
              getData('products', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setproducts', responseData.products)
                }
              })
              break;
            case 'classtimes':
              getData('classtimes', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setclasstimes', responseData.classtimes)
                }
              })
              break;
            case 'subgoals':
              getData('subgoals', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setsubgoals', responseData.subgoals)
                }
              })
              break;
            case 'rooms':
              getData('rooms', (err, responseData) => {
                this.loading = false
                if (err) {
                  this.error = err.toString()
                } else {
                  store.commit('setrooms', responseData.rooms)
                }
              })
              break;
            case 'teachergroups':
                getData('teachergroups', (err, responseData) => {
                  this.loading = false
                  if (err) {
                    this.error = err.toString()
                  } else {
                    store.commit('setteachergroups', responseData.teachergroups)
                  }
                })
                break;
          }

        }

      }

    });

    this.socket.on("connect", () => {

      store.commit('setsocketiostate', true);

      // send current route

      this.sendRouteChange(router.currentRoute);

    });

    this.socket.on("disconnect", () => {

      store.commit('setsocketiostate', false);

    });

  }

  sendRouteChange(routeObject) {

    if (this.socket != undefined) {

      var send = {
        name: routeObject.name,
        params: routeObject.params,
        path: routeObject.path
      }

      this.socket.emit('route', JSON.stringify(send));

    }

  }

  disconnect() {

    if (this.socket) {

      this.socket.disconnect();

    }

  }

}

export default new SocketioService();
