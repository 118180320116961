<template>
  <div class="home">
    <br>
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <div v-if="!(Object.keys($store.state.generalinfo).length === 0)">
      <div v-if="$store.state.generalinfo.opensubgoals != 0">
        <b-alert show>{{ $t('home.opensubgoals') }}</b-alert>
      </div>
      <div v-if="$store.state.generalinfo.openparameters != 0">
        <b-alert show>{{ $t('home.openparameters') }}</b-alert>
      </div>
      <div v-if="$store.state.generalinfo.useropensubgoals != 0">
        <b-alert show>{{ $t('home.useropensubgoals') }}</b-alert>
      </div>
      <div v-if="$store.state.generalinfo.useropenparameters != 0">
        <b-alert show>{{ $t('home.useropenparameters') }}</b-alert>
      </div>
    </div>
    <b-row>
      <b-col cols="8">
        <span v-for="message in $store.state.messages" :key="message.title">
          <div v-if="!message.hidden">
            <b-card :title="message.title">
              <b-card-text v-html="message.message"></b-card-text>
            </b-card>
            <br>
          </div>
        </span>
      </b-col>
      <b-col>
        <b-card no-body :header="$t('home.agenda.title')" class="mb-4">
          <b-button-group>
            <b-button variant="primary" v-on:click="changedate(-1)"><font-awesome-icon icon="left" class="fa-lg" /></b-button>
            <b-button variant="primary" v-on:click="changedate(+1)"><font-awesome-icon icon="right" class="fa-lg" /></b-button>
          </b-button-group>
          <DayPilotCalendar id="dphome" :config="configcalendar" ref="dphome" />
          <b-form-datepicker v-model="selectedDate" class="mt-2" @context="onContext" :locale="$i18n.locale" start-weekday="1" :label-help="$t('calendar.dateselection.help')" :label-no-date-selected="$t('calendar.dateselection.nodateselected')"></b-form-datepicker>
        </b-card>
        <b-card no-body :header="$t('home.teachersonline')">
          <b-list-group flush>
            <b-list-group-item v-for="user in $store.state.userroutes" :key="user.id">
              {{ $t('home.teacher', { firstName: $store.getters.getTeacherFirstName(user.id), lastName: $store.getters.getTeacherLastName(user.id) }) }}
              <ul>
                <li v-for="session in user.sessions" :key="session.id">
                  <b-link :to="{ name: session.routefull.name, params: session.routefull.params }">{{ $t('routes.' + session.routefull.name.toLowerCase()) }}</b-link>
                </li>
              </ul>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { postData } from '@/api'
import { DayPilotCalendar } from '@daypilot/daypilot-lite-vue'

  export default {
    name: 'Home',
    components: {
      DayPilotCalendar,
    },
    mounted() {
      this.$nextTick(() => {
        this.loadCalendarEvents()
      })
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: this.$t('home.breadcrumb'),
            active: true
          }
        ],
        selectedDate: '',
        configcalendar: {
          locale: this.$i18n.locale,
          viewType: "Day",
          showAllDayEvents: true,
          cellDuration: 10,
          cellHeight: 20,
          businessBeginsHour: 8,
          businessEndsHour: 18,
          timeRangeSelectedHandling: "Disabled",
          eventDeleteHandling: "Disabled",
          eventMoveHandling: "Disabled",
          eventResizeHandling: "Disabled",
          onEventClick: async (args) => {
            if (args.e.data.type == 'class') {
              // check if it's a event or agenda item, if agenda item link to the correct agenda route
              if ('agenda' in args.e.data && args.e.data.agenda == true) {
                this.$router.push({ name: 'AgendaEntry', params: {classid: JSON.stringify(args.e.data.routedata.classid).replace(/"/g,''), date: args.e.data.routedata.date, period: 0, course: args.e.data.routedata.course } })
              } else if ('agendaov3' in args.e.data && args.e.data.agendaov3 == true) {
                this.$router.push({ name: 'AgendaEntryov3', params: {classid: JSON.stringify(args.e.data.routedata.classid), date: args.e.data.routedata.date} })
              } else {
                //console.log('normal event');
                //console.log(args.e.data)
              }
            } else {
              // open calendar route on day of event
              //console.log(args.e.data)
              //console.log('normal event');
            }
          },
        },
      }
    },
    computed: {
      dphome() {
        return this.$refs.dphome.control;
      },
    },
    methods: {
      onContext(ctx) {
        if(ctx.selectedYMD != '') {
          this.dphome.update({startDate: ctx.selectedYMD});
          this.loadCalendarEvents();
        }
      },
      changedate(change) {
        var date;
        if (this.selectedDate == '') {
          // take todays date and update it
          date = new Date()
          date.setDate(date.getDate() + change );
          this.selectedDate = date.toJSON().slice(0, 10)
        } else {
          // cange the selected date and update it with the change in the string
          date = new Date(this.selectedDate)
          date.setDate(date.getDate() + change );
          this.selectedDate = date.toJSON().slice(0, 10)
        }
      },
      loadCalendarEvents() {
        var events = [];
        postData('calendar/events', { start: this.dphome.visibleStart().value, end: this.dphome.visibleEnd().value }, (err, responseData) => {
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              if (this.$route.name != 'Login') {
                this.$store.commit('setuserloggedout')
                this.$router.push({ name: 'Login' })
              }
            }
          } else {
            events = responseData.events
            this.dphome.update({events});
          }
        })
      },
    }
  }
</script>
