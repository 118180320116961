<template>
  <div class="text-center text-primary">
    <font-awesome-icon icon="spinner" class="fa-10x fa-spin" />
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },
  name: 'Loading'
}
</script>
