<template>
  <div id="app">
    <Navbar />
    <b-container fluid class="pl-4 pr-4">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import SocketioService from '@/services/socketio.service.js';

export default {
  components: {
    Navbar
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  watch:{
    $route (to){
      SocketioService.sendRouteChange(to)
    }
  }
}
</script>

<style lang="scss">
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.text-yellow {
  color: #ffc107;
}
.text-orange {
  color: #fd7e14;
}
.text-purple {
  color: #6f42c1;
}
.text-teal {
  color: #20c997;
}
.text-blue {
  color: #007bff;
}
</style>
