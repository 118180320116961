export default {
  "account": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "calendarsettings": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar settings"])},
      "changeteachercalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "changeteachergroupcalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "changeusercalendars": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour:"])},
      "defaultcalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default calendars"])},
      "defaultcalendarsdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "defaultcalendarshome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default calendars (Home)"])},
      "defaultcalendarshomedescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "permissionsexplination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings changed"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "teachergroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher groups"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])}
    },
    "changepassword": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password incorrect"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password:"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    },
    "forgotpassword": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Try again."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email for a password reset link."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email below and a reset link will be emailed to you."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])}
    },
    "forgotpasswordreset": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, try again."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your new password below."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])}
    },
    "login": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password combination not found"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "forgotpasswordlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google Workspace account"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developed in partnership with De Zeeparel"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
      "smartschool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Smartschool account"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged in"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "toekomstperspectiefaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or login with email-address"])}
    },
    "logout": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged out"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "switch": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged in as the new user"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    }
  },
  "admin": {
    "archive": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "reportsarchive": {
        "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
        "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated"])},
        "schoolyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolyear"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived reports"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])},
        "types": {
          "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP"])},
          "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP overview"])},
          "matrixclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix class"])},
          "matrixstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix student"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        }
      },
      "reportscompetence": {
        "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["School year: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence reports"])}
      },
      "reportsihp": {
        "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["School year: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP reports"])}
      }
    },
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator functions"])},
    "checkentry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check entry"])}
    },
    "log": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "actions": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      },
      "actiontype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action type"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
      "ipaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip address"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])}
    },
    "matrixgraphs": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix graphs"])},
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "selectexplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the Ctrl key to select multiple."])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students:"])}
    },
    "spread": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread"])}
    }
  },
  "agenda": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])},
    "entry": {
      "backbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "classtimes": {
        "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the Ctrl key to select multiple"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class times:"])}
      },
      "copybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
      "copymodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy agenda item"])}
      },
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda item deleted"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "editmodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda item edit"])}
      },
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "override": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override all"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "secondstep": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To next step"])}
      },
      "secondstepedit": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To next step"])}
      },
      "subject": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject:"])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item added"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "table": {
        "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classtimes: "])}
      },
      "textareatitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson contents: "])}
    },
    "entryov3": {
      "attachmentinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry OV3"])},
      "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
      "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class times:"])},
      "copy": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item copied."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "copybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
      "copymodal": {
        "newdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New date:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course:"])},
      "dateselection": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use cursor keys to navigate calendar dates"])},
        "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date selected"])}
      },
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item deleted."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "editmodal": {
        "deleteattachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "overrideall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "evaluate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluations saved"])}
      },
      "evaluatebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate"])},
      "evaluatemodal": {
        "overrideall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate"])}
      },
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation: "])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file here"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file or drop it here."])}
        },
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "nocourseselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "overrideall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "onlymyselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson subject:"])},
      "table": {
        "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
        "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class time"])},
        "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson subject"])},
        "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "gotoentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to entry page"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])}
    },
    "reportclass": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "reportteachers": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "attendance": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "informatexport": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export informat file"])}
    }
  },
  "calendar": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "dateselection": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use cursor keys to navigate calendar dates"])},
      "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date selected"])}
    },
    "edit": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "createdby": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created by: ", _interpolate(_named("name"))])},
      "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date: ", _interpolate(_named("time"))])},
      "displayonly": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted invitation"])},
        "end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["End time: ", _interpolate(_named("name"))])},
        "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Title: ", _interpolate(_named("name"))])},
        "room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Room: ", _interpolate(_named("room"))])},
        "start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start: ", _interpolate(_named("name"))])}
      },
      "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time:"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])},
      "notaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room:"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time: "])},
      "teachersinvited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit item"])}
    },
    "new": {
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar:"])},
      "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date: ", _interpolate(_named("time"))])},
      "end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["End: ", _interpolate(_named("time"))])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no room selected"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])},
      "repeatdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "repeattimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room:"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start: ", _interpolate(_named("time"))])},
      "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time: "])},
      "teachersinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite teachers:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New item"])}
    },
    "table": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "competence": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence score"])},
    "discussion": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion"])},
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change saved"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])}
    },
    "entry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "printbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])}
    }
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "file": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "career": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School career"])},
      "year1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 1"])},
      "year2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 2"])},
      "year3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 3"])},
      "year4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 4"])},
      "year5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 5"])},
      "year6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 6"])},
      "year7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 7"])},
      "year8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year 8"])}
    },
    "charts": {
      "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison competence score."])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison of the number of completed goals per category with the most recent matrix."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs"])}
    },
    "class": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal overview"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view the goals in each category "])}
    },
    "note": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New note"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note changed"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("TeacherFirstName")), " ", _interpolate(_named("TeacherLastName")), " Wrote on ", _interpolate(_named("date")), ":"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])}
    },
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])}
    },
    "reportsarchive": {
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
      "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated"])},
      "schoolyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolyear"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived reports"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])},
      "types": {
        "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence score"])},
        "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP"])},
        "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP overview"])},
        "matrixclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix class"])},
        "matrixstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix student"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      }
    },
    "reportscompetence": {
      "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["School year: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence reports"])}
    },
    "reportsihp": {
      "schoolyear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["School year: ", _interpolate(_named("yearstart")), "-", _interpolate(_named("yearend"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP reports"])}
    },
    "reportspreview": {
      "ihp": {
        "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report for parents"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report for the school"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihp"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print preview reports"])}
    },
    "selectedgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected goals"])},
    "student": {
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class:"])},
      "openStudentPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open studentportal"])},
      "startyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start school year:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])}
    }
  },
  "gwp": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwp"])},
    "printbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])}
  },
  "help": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "email": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support (AT) toekomstperspectief.be"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can email to the address above with questions."])}
    },
    "list": {
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])},
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals and subgoals"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview of available:"])}
    },
    "statustitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanners status"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])}
  },
  "home": {
    "agenda": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])}
    },
    "agendatoday": {
      "classitem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("classname")), " : ", _interpolate(_named("course"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's agenda"])}
    },
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "openparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are parameter suggestions."])},
    "opensubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are subgoal suggestions."])},
    "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
    "teachersonline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online teachers"])},
    "useropenparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You still have open parameter suggestions."])},
    "useropensubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You still have open subgoal suggestions."])},
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])}
  },
  "ihp": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAP"])},
    "checkentry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check entries"])}
    },
    "entry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "collapsebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open / close"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation deleted"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "duplicate": {
        "allclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All classes"])},
        "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes:"])},
        "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])}
      },
      "duplicatebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
      "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Changes have not been saved. error code: ", _interpolate(_named("error"))])},
      "errorlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no level selected."])},
      "errorparameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no parameter selected."])},
      "errorpermissiondenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "gotoreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the report of this student"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level:"])},
      "matrixvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Matrix value:"])},
      "newparameter": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion is saved"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "newsubgoal": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion saved"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "nextperiod": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, your change has not been saved."])},
        "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taken to next period"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take to next period"])}
      },
      "nextperiodbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take to next period"])},
      "nextstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next student"])},
      "noteinternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal note:"])},
      "notereport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report note:"])},
      "notime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal was not evaluated"])},
      "parameter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parameter 1:"])},
      "parameter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parameter 2:"])},
      "parameter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parameter 3:"])},
      "previousstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous student"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "reusewarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This subgoal has been used a lot for this student in the past."])},
      "reusewarningov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This goal has been used a lot for this student in the past."])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "showonreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show on report"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved successfully"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "suggestnew": {
        "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New parameter:"])},
        "subgoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subgoal."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subgoal or parameter"])}
      },
      "suggestnewbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggest new parameter or subgoal"])}
    },
    "entrycontent": {
      "agendacount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cardtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("student")), " - ", _interpolate(_named("goal"))])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation deleted."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "endevaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change not saved."])},
      "errorpermissiondenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "evaluations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "globalparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "goalmodal": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])}
      },
      "matrixvaluegoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "matrixvalueparameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "newscorecomment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score comment:"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
      "otherparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "parameter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 1:"])},
      "parameter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 2:"])},
      "parameter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 3:"])},
      "parametermeasures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "score": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "newscoretypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "submitnewscore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change saved"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "typeradios": {
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
        "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "entrymeasures": {
      "addattachmentbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachment"])},
      "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments:"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change not saved."])},
      "errormissingdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "errorpermissiondenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "ledgend": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "ledgendbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show / hide legend"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
      "parameter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 1:"])},
      "parameter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 2:"])},
      "parameter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter 3:"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change saved."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "uploadmodal": {
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file here"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file or drop it here."])}
        },
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachment"])}
      }
    },
    "newreport": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "modal": {
        "courseby": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("teacher")), " for ", _interpolate(_named("course"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing"])}
      },
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    },
    "overview": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All years"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report message:"])},
      "noteprivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal message:"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher: "])}
    },
    "overviewov3": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "gotoentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "table": {
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])}
      }
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal: "])},
      "gotoentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to entry page"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation:"])},
      "noteprivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal note:"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload report"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "simplifybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide items that do not show up on the reports for the parents."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    }
  },
  "matrix": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "entry": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
      "editbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change not saved."])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
      "evaluation": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation saved successfully"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Excel file."])},
      "fixbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix issues with new students in the middle of a school year."])},
      "fixstudent": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script executed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "ledgend": {
        "abbreviations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviations"])},
        "abbreviationsng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ND: No data"])},
        "colorcodes": {
          "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the most important ones to work with"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal, no specific goal or already worked on it"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work has already been done with this goal in the past or is already OK for all students, so can no longer be selected as a priority"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color codes"])},
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["still needs some work"])}
        }
      },
      "ledgendbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show / hide legend"])},
      "modal": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overrides"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This goal is for following students ok or voldoende!"])},
        "warningtimesselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This goal has been evaluated a lot already for this class."])}
      },
      "newstudent": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student setup"])}
      },
      "nostudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no students in this class."])},
      "nostudentstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
      "note": {
        "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course:"])},
        "hppe1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hppe1:"])},
        "hppe2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hppe2:"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
        "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note saved successfully"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "openmodalbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show goal selection"])},
      "openseccondmodalbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select different goal for student."])},
      "printbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "reportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open report"])},
      "savebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "setupbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup student (sets everything not entered as in development.)"])},
      "simplifybutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplify"])},
      "studentokgoalmodal": {
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "report": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])}
    }
  },
  "matrixupdate": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), " updated a value"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "messages": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])}
  },
  "navbar": {
    "admin": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "checkadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check entry admin"])},
      "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual action plan edit"])},
      "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
      "matrixgraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix graphs"])},
      "spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator functions"])}
    },
    "agenda": {
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "entrymultiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry over multiple days"])},
      "entryov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry OV3"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "reportclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "reportteachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])}
    },
    "alerts": {
      "calendarinvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "calendarupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])}
    },
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "competence": {
      "discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence"])}
    },
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student file"])},
    "gwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group work plan"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "ihp": {
      "checkentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check entry"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAP entry"])},
      "entrycontent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "entrymeasures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry supporting measures"])},
      "newreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New report"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAP overview"])},
      "overviewov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAP report"])},
      "reportov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Induvidual action plan"])}
    },
    "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "pos": {
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders today"])},
      "ordersrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders over multiple days"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "studentoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student overview"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
      "teacheroverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher overview"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders today"])},
      "topups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-ups today"])},
      "topupsrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "webpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web POS"])}
    },
    "pwreset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool account password reset"])},
    "reports": {
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All courses"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview per domain"])},
      "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate report pdf files"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All goals"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview per period"])},
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All subgoals"])},
      "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview subgoal suggestions"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs"])}
    },
    "settings": {
      "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendars"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development goal"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
      "classschedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class schedules"])},
      "classtimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class times"])},
      "competenceanchors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence anchors"])},
      "competences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competences"])},
      "coursegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course groups"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
      "educationforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education forms"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])},
      "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "moments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluationmoments"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "parameterssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter suggestions"])},
      "perspectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
      "subgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoals"])},
      "subgoalssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal suggestions"])},
      "teachergroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher groups"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    },
    "socketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time update status"])},
    "socketviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of persons on this exact page"])},
    "user": {
      "calendarsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar settings"])},
      "changepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logoff"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change user"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    }
  },
  "notfound": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take Me Home"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, an error has occured, Requested page not found!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Not Found"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])}
  },
  "pagewatching": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), " is also watching this page now."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "pos": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS"])},
    "orders": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order has been deleted."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete order"])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "orderid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order id"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Orders total € ", _interpolate(_named("total"))])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    },
    "ordersrange": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders over multiple days"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date:"])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date:"])}
    },
    "products": {
      "addoption": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option added"])}
      },
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product archived"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
      "changeproductterminal": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed"])}
      },
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating product failed"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "modal": {
        "addcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add credit"])},
        "addoption": {
          "defaultenabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default enabled"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name: "])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: "])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option"])}
        },
        "options": {
          "defaultenabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default enabled"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
        },
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "reducecredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce credit"])},
        "showinkiosk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in student kiosk"])},
        "terminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS terminals"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "addcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add credit"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "reducecredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce credit"])},
        "showinkiosk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in student kiosk"])}
      },
      "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product created"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "studentoverview": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student overview"])},
      "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At ", _interpolate(_named("time")), " there was a transaction of ", _interpolate(_named("value")), "."])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At ", _interpolate(_named("time")), " ", _interpolate(_named("value")), " was added to credit."])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current value on card € ", _interpolate(_named("total"))])}
    },
    "students": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student overview"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current credit on card"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])}
    },
    "teacheroverview": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher overview"])},
      "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At ", _interpolate(_named("time")), " there was a transaction of ", _interpolate(_named("value")), "."])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At ", _interpolate(_named("time")), " ", _interpolate(_named("value")), " was added to credit."])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current value on card € ", _interpolate(_named("total"))])}
    },
    "teachers": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers overview"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current credit on card"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])}
    },
    "topups": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top ups"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup deleted"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "orderid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order id"])},
      "product": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " € ", _interpolate(_named("price"))])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Received total € ", _interpolate(_named("total"))])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])}
    },
    "topupsrange": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders over multiple days"])},
      "deletebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date:"])},
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date:"])}
    },
    "webpos": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online POS"])},
      "cart": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Place order € ", _interpolate(_named("price"))])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])}
      },
      "checkout": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place order"])},
        "student": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Student: ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
        "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Teacher: ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      },
      "errorcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The student does not have enough credit left"])},
      "errorgeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
      "modal": {
        "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add € ", _interpolate(_named("price"))])},
        "option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (€ ", _interpolate(_named("price")), ")"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Product: ", _interpolate(_named("productname"))])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type here to search"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order has been placed."])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    }
  },
  "pwreset": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool password reset"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "modal": {
      "mainaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainaccount"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Change password for ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])}
    },
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "smusername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool username"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed"])},
    "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
  },
  "reports": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs"])},
    "courses": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    },
    "download": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download reports"])},
      "buttonsinglereport": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download ", _interpolate(_named("reportname")), " report for ", _interpolate(_named("student"))])},
      "popupnotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If not all the files are downloaded it may be that the browser blocks popups."])},
      "reports": {
        "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence report"])}
      }
    },
    "generate": {
      "allnotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All notes"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate reports"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate reports, this can take a while!"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
      "classfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class report"])},
      "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence report"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, did you select a class?"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "ihp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP Report"])},
      "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP overview"])},
      "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal report"])},
      "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
      "ov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report for parents"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period:"])},
      "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports generated"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This generates the pdf reports for the selected reports and classes. This can take some time and is best only used at the end of a period to create an archive. It is recommended to not do all classes at once."])}
    },
    "goals": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspective"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])}
    },
    "subgoals": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoals"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "evaluationtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation type"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoals"])}
    }
  },
  "routes": {
    "admincheckentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "adminlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin -> Log"])},
    "adminspread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin -> Spread"])},
    "agendaentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda Entry"])},
    "agendaentrymultiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda entry over multiple days"])},
    "agendaentryov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "agendareport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda Report"])},
    "agendareportteacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda overview per teacher"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "changepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User -> Change password"])},
    "competencediscussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "competenceentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence Entry"])},
    "competencereport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence report"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student file"])},
    "gwp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupworkplan"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "ihpcheckentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP Check entry"])},
    "ihpentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP Entry"])},
    "ihpentrycontent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ihpentrymeasures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ihpoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP Overview"])},
    "ihpoverviewov3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ihpreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHP Report"])},
    "matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "matrixreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix report"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "posorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS Orders"])},
    "posordersrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS Orders multiple days"])},
    "posproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS Products"])},
    "posstudentoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS Student overview"])},
    "posstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS Students overview"])},
    "postopups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS Top-ups"])},
    "postopupsrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "poswebpos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online POS"])},
    "pwreset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartschool password reset"])},
    "reportscourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reportsgenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reportsgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reportssubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsclasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsclasstimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingscompetenceanchors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingscompetences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingscourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsgeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsmessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsparameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsparameterssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingssubgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingssubgoalssuggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "settingsteachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "usercalendarsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User calendar settings"])},
    "userswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "select": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select course"])},
    "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select domain"])},
    "evaluationmoment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select evaluation moment"])},
    "evaluationmomentcompetence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select evaluation moment"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select goal"])},
    "nofilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filter"])},
    "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select domain"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select student"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select teacher"])}
  },
  "settings": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "calendars": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendars"])},
      "changeteachercalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "changeteachergroupcalendar": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "enablecalendarforall": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "enableforall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "teachergroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher groups"])},
        "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit calendar"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new calendar"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "permissions": {
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read, write and delete"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read and write"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar updated"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "categories": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "changecategorycourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "courserestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course restricted"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "hiddeningraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden in graphs"])},
      "modal": {
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour:"])},
        "courseRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course restricted"])},
        "hiddeningraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide category in graphs"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit category"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new category"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category updated."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "classes": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
      "changeclasscourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "changeteacherclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "hascourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "hasstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has students"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
      "iconupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon changed"])}
      },
      "modal": {
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file here"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file or drop it here."])}
        },
        "icon": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new icon"])}
        },
        "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide icons on reports."])},
        "matrixhidegoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "schoolyearstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit class"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New class"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "orderupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "smartschoolsynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This class is synchronised with smartschool, some changes may be overwritten by the next synchronisation."])},
      "sortmodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change order"])}
      },
      "update": {
        "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "uploadmodal": {
          "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "classschedules": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    },
    "classtimes": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The class time is archived."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class times"])},
      "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit class time"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New class time"])},
        "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name: "])},
        "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class time added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "update": {
        "endtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name: "])},
        "starttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class time updated."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only correct spelling mistakes otherwise the reports will be incorrect!"])}
      }
    },
    "competenceanchors": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence anchors"])},
      "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competence"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "competence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "competences": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competences"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New competence"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "courses": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "coursegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course group"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "iconupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])}
      },
      "modal": {
        "alwaysallgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always display all goals in IHP for this course."])},
        "educationform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education form"])},
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file here"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file or drop it here."])}
        },
        "icon": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new icon"])}
        },
        "ihponlyshownote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "namealert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only fix spelling mistakes here. If you change the name this also changes the historical data."])},
        "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit course"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New course"])},
        "educationform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educationform"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course added"])}
      },
      "orderupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "update": {
        "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "domains": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
      "changeperspectiveclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspective updated"])}
      },
      "changeperspectivecourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain updated"])}
      },
      "classrestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class limited."])},
      "courserestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course limited."])},
      "educationforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education form"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "classRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show for selected classes."])},
        "courseRestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show for selected courses."])},
        "matrixsubscores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate development goal score in matrix."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit domain"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New domain"])},
        "educationform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education form:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain updated"])}
      }
    },
    "general": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings"])},
      "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type text here to send to all logged in users"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change setting"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value:"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
    },
    "goals": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "changegoalcourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "courserestricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "modal": {
        "schoolyearstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit goal"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New goal"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category:"])},
        "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal created"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "perspectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "messages": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "parameters": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit parameter"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New parameter"])},
        "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain:"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "update": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter updated"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only correct spelling mistakes otherwise the reports will be incorrect!"])}
      }
    },
    "parameterssuggestions": {
      "add": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter added."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters suggestions"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter suggestion deleted."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])}
    },
    "rooms": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
      "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit room"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New room"])},
        "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "orderupdate": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "resourcecalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sortmodal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change order"])}
      },
      "update": {
        "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "resourcecalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in calendar"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room updated"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "students": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student archived."])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "courses": {
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "exportbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "imageupdate": {
        "iconsuccesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "modal": {
        "file": {
          "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
          "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At boarding school"])},
        "picture": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "smartschoolignoreclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not synchronise this class with smartschool."])},
        "subaccounts": {
          "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name:"])},
          "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type:"])}
        },
        "subclasses": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add class"])}
        },
        "takesbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takes the school bus"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit student"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
        "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class: "])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname: "])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name: "])},
        "startyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start schoolyear: "])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "subaccount": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change saved"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "subaccounts": {
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
        "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup or code"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of user"])},
        "types": {
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
          "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
          "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])}
        }
      },
      "subclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change saved"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "subclasses": {
        "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary class"])}
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "subgoals": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal archived."])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoals"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit subgoal"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only correct spelling mistakes here otherwise the historical data is going to be invalid."])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subgoal"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category: "])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal: "])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal: "])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subgoal created"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "perspectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief"])},
      "subgoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal"])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal updated"])}
      }
    },
    "subgoalssuggestions": {
      "add": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal added."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgoal suggestions"])},
      "delete": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion deleted."])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "modal": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])}
    },
    "teachergroups": {
      "archive": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher group archived"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher groups"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit teacher group"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New teacher group"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher group created"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "update": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher group updated"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    },
    "teachers": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
      "changeteacherclass": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "changeteachercourse": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "changeteacherteachergroup": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
      "modal": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher is active (can login)"])},
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher is administrator"])},
        "readonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher is read only"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit teacher"])}
      },
      "new": {
        "cardtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New teacher"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: "])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname: "])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname: "])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher added"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      },
      "readonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read only"])},
      "smartschoolsynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher is synced with smartschool, some changes may be overwritten at the next synchronization."])},
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher changed"])},
        "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
      }
    }
  },
  "studentlink": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick links:"])}
  },
  "tiptap": {
    "imageupload": {
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "dropplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  }
}