<template>
  <div class="login">
    <br>
    <h1>{{ $t('account.logout.title') }}</h1>
    <div v-if="success">
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  import { getData } from '@/api'
  import * as Sentry from "@sentry/vue";

  export default {
    name: 'Login',
    components: {
      Loading
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        success: null,
        show: true,
        loginSmartschool: null,
        loginGworkspace: null,
        loginAd: null,
        loginLdap: null
      }
    },
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.error = null
        this.loading = true
        getData('account/logout', (err) => {
          this.loading = false
          if (err) {
            this.error = err.toString()
          } else {
            this.success = true
            // show success toast
            this.$root.$bvToast.toast(this.$t('account.logout.success'), {
              title: this.$t('account.logout.successtitle'),
              autoHideDelay: 2500,
            })
            // sentry logout
            Sentry.configureScope(scope => scope.setUser(null));
            // destroy local data
            this.$store.commit('setuserloggedout')
            // redirect to login
            this.$router.push({ name: 'Login' })
          }
        })
      }
    }
  }
</script>
