<template>
  <div class="login">
    <br>
    <h1 class="text-center">{{ $t('account.login.title') }}</h1>
    <div v-if="success">
      <b-row>
        <b-col md="2"></b-col>
        <b-col md="8">
          <b-card-group deck>
            <b-card class="text-center d-none d-lg-block" border-variant="white">
              <div>
                <br>
                <img src="@/assets/logo.svg" class="loginlogo" alt="Logo">
                <br><br>
                <p>{{ $t('account.login.partner') }}</p>
                <img src="@/assets/logodzp.png" class="loginlogodzp" alt="Logo De Zeeparel">
              </div>
            </b-card>
            <b-card class="text-center">
              <div>
                <div v-if="loginSmartschool">
                  <b-button block variant="outline-primary" href="/account/smartschoollogin.php">{{ $t('account.login.smartschool') }}</b-button>
                  <br>
                </div>
                <div v-if="loginGworkspace">
                  <b-button block variant="outline-primary" href="/account/gsuitelogin.php">{{ $t('account.login.google') }}</b-button>
                  <br>
                </div>
                <p class="text-center">{{ $t('account.login.toekomstperspectiefaccount') }}</p>
                <b-form @submit="onSubmit">
                  <label for="text-email">{{ $t('account.login.email') }}</label>
                  <b-form-input type="email" id="text-email" v-model="form.email"></b-form-input>
                  <label for="text-password">{{ $t('account.login.password') }}</label>
                  <b-form-input type="password" id="text-password" v-model="form.password" autocomplete="current-password"></b-form-input>
                  <br>
                  <b-button :disabled="working" type="submit" variant="primary" v-b-tooltip.hover :title="$t('account.login.button')" id="user-login-button"><font-awesome-icon icon="sign-in" class="fa-lg" /></b-button>
                </b-form>
                <br>
                <p>{{ $t('account.login.forgotpassword') }} <b-link :to="{ name: 'UserForgotpassword' }" id="user-login-forgot-link">{{ $t('account.login.forgotpasswordlink') }}</b-link></p>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col md="2"></b-col>
      </b-row>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  import { getData, postData } from '@/api'
  import * as Sentry from "@sentry/vue";
  import SocketioService from '@/services/socketio.service.js';

  export default {
    name: 'Login',
    components: {
      Loading
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        success: null,
        show: true,
        loginSmartschool: null,
        loginGworkspace: null,
        baseurl: null,
        working: false
      }
    },
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.error = null
        this.loading = true
        getData('loginsettings', (err, responseData) => {
          this.loading = false
          this.working = false
          if (err) {
            this.error = err.toString()
          } else {
            this.success = true
            this.loginSmartschool = responseData.loginSmartschool
            this.loginGworkspace = responseData.loginGworkspace
            this.baseurl = responseData.baseurl
          }
        })
      },
      onSubmit(event) {
        event.preventDefault()
        this.error = null
        this.loading = true
        this.working = true
        postData('account/login', this.form, (err, responseData) => {
          this.loading = false
          if (err) {
            this.error = err.toString()
            this.$root.$bvToast.toast(this.$t('account.login.error'), {
              title: this.$t('account.login.errortitle'),
              variant: "danger",
              autoHideDelay: 2500,
            })
            this.working = false
          } else {
            // show success toast
            this.$root.$bvToast.toast(this.$t('account.login.success'), {
              title: this.$t('account.login.successtitle'),
              autoHideDelay: 2500,
            })
            // register user in sentry
            Sentry.setUser({ id: responseData.user.id + '@' + this.baseurl });
            // take user data and then add that to the store
            this.$store.commit('setbaseurl', responseData.baseurl)
            this.$store.commit('setuserdata', responseData.user)
            // connect socket io
            if (this.$store.state.user.id != null && this.$store.state.user.sessionUuid != null) {
              SocketioService.setupSocketConnection(this.$store.state.user.sessionUuid);
            }
            // get all the other stuff for the Store
            getData('systemsettings', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setsettings', responseData.settings)
              }
            })
            getData('generalinfo', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setgeneralinfo', responseData.generalinfo)
              }
            })
            getData('messages', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setmessages', responseData.messages)
              }
            })
            getData('teachers', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setteachers', responseData.teachers)
              }
            })
            getData('classes', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setclasses', responseData.classes)
              }
            })
            getData('students', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setstudents', responseData.students)
              }
            })
            getData('hppe', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('sethppe', responseData.hppe)
              }
            })
            getData('levels', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setlevels', responseData.levels)
              }
            })
            getData('matrixparameters', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setmatrixparameters', responseData.matrixparameters)
              }
            })
            getData('evaluationmoments', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setevaluationmoments', responseData.evaluationmoments)
              }
            })
            getData('evaluationmomentscompetence', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setevaluationmomentscompetence', responseData.evaluationmomentscompetence)
              }
            })
            getData('perspectives', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setperspectives', responseData.perspectives)
              }
            })
            getData('competenceparameters', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setcompetenceparameters', responseData.competenceparameters)
              }
            })
            getData('coursegroups', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setcoursegroups', responseData.coursegroups)
              }
            })
            getData('goalcategories', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setgoalcategories', responseData.goalcategories)
              }
            })
            getData('educationforms', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('seteducationforms', responseData.educationforms)
              }
            })
            getData('anchors', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setanchors', responseData.anchors)
              }
            })
            getData('parameters', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setparameters', responseData.parameters)
              }
            })
            getData('goals', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setgoals', responseData.goals)
              }
            })
            getData('courses', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setcourses', responseData.courses)
              }
            })
            getData('products', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setproducts', responseData.products)
              }
            })
            getData('classtimes', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setclasstimes', responseData.classtimes)
              }
            })
            getData('subgoals', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setsubgoals', responseData.subgoals)
              }
            })
            getData('rooms', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setrooms', responseData.rooms)
              }
            })
            getData('teachergroups', (err, responseData) => {
              this.loading = false
              if (err) {
                this.error = err.toString()
              } else {
                this.$store.commit('setteachergroups', responseData.teachergroups)
              }
            })
            // redirect to home page
            this.$router.push({ name: 'Home' })
          }
        })
      }
    }
  }
</script>

<style scoped>
.loginlogo{
  width: 100%;
  max-height: 220px;
  object-fit: contain;
}
.loginlogodzp{
  width: 75%;
  max-height: 100px;
  object-fit: contain;
}
</style>
