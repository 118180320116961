<template>
  <div class="loginsmartschool">
  </div>
</template>

<script>
export default {
  name: 'Loginsmartschool',
  created() {
    window.location.href = "account/smartschoollogin.php";
  }
}
</script>
